<template>
<div class="photos">
    <div class="title"><p class="tl">{{ $t("photoLibrary") }}</p></div>
    <v-container class="photos_cont">

        <template v-if="photoLoad">

            <div class="photos_desktop" >
                <div class="row3 photos"> 
                    <div class="col column1" height="100%" style="display: block;">
                        <v-skeleton-loader height="100%" style="margin: 0 5px; height: 615px" v-bind="attrs" type="image@3"></v-skeleton-loader>
                    </div>
                    <div class="col column2" >
                        <v-skeleton-loader class=" p0" style="margin: 0 5px;" v-bind="attrs" type="image@2"></v-skeleton-loader>
                        <v-skeleton-loader class=" p1" style="margin: 0 5px;" v-bind="attrs" type="image@2"></v-skeleton-loader>
                    </div>
                    <div class="col column3" >
                        <v-skeleton-loader class=" p0" style="margin: 0 5px;" v-bind="attrs" type="image@2"></v-skeleton-loader>
                        <v-skeleton-loader class=" p1" style="margin: 0 5px;" v-bind="attrs" type="image@2"></v-skeleton-loader>
                    </div>
                </div>
            </div>
            
        </template>
        <template v-else v-for="s in photoPage">

            <div class="photos_desktop" :key="s">
                <div class="row3 photos"> 
                    <template v-for="index in 3" >
                    
                        <div :class="'col column'+index" :key="index">
                            <template v-for="(i, x) in photoSlice (photosData, index, s)">
                                <v-img :lazy-src="Url+'/libraryImage/small_thumb/'+i.url_image" style="cursor:pointer" :class="'p'+x" v-if="i.url_image" aspect-ratio="1" data-fancybox="gallery" :data-caption="i.title" :href="Url+'/libraryImage/thumb/'+i.url_image" 
                                        :src="Url+'/libraryImage/thumb/'+i.url_image" class="white--text" :key="i"></v-img>
                            </template>
                        </div>

                    </template>
                </div>
                <div class="row3 pp">
                    <template v-for="(i, x) in photoSliceRow (photosData, 7, 3, s)"> 
                        <div :class="'rr dcolumn'+x" :key="i">
                            <a data-fancybox="gallery" :data-caption="i.title" :href="Url+'/libraryImage/thumb/'+i.url_image"  :key="i">
                                <v-img :lazy-src="Url+'/libraryImage/small_thumb/'+i.url_image" v-if="i.url_image" aspect-ratio="1" :src="Url+'/libraryImage/thumb/'+i.url_image"  class="white--text" :class="'r'+x" :key="i"></v-img>
                            </a>
                        </div>
                    </template>
                </div>
            </div>

        </template>


        <template v-if="photoLoad">

            <div class="photos_tab d-none" >
                <div class="row3 photos"> 
                    <div class="col column1" >
                        <v-skeleton-loader class=" p0" style="margin: 0 5px;" v-bind="attrs" type="image@2"></v-skeleton-loader>
                        <v-skeleton-loader class=" p1" style="margin: 0 5px;" v-bind="attrs" type="image@2"></v-skeleton-loader>
                    </div>
                    <div class="col column2" >
                        <v-skeleton-loader class=" p0" style="margin: 0 5px;" v-bind="attrs" type="image@2"></v-skeleton-loader>
                        <v-skeleton-loader class=" p1" style="margin: 0 5px;" v-bind="attrs" type="image@2"></v-skeleton-loader>
                    </div>
                </div>
            </div>
            
        </template>
        <template v-else v-for="s in photoPage">
            <div class="photos_tab d-none" :key="s">
                <div class="row3 photos"> 
                    <template v-for="index in 5" >
                    
                        <div :class="'col column'+index" :key="index">
                            <template v-for="(i, x) in photoSliceTab (photosData, index, s)">
                                <a data-fancybox="gallery" :data-caption="i.title" :href="Url+'/libraryImage/thumb/'+i.url_image"  :key="i">
                                    <v-img :lazy-src="Url+'/libraryImage/small_thumb/'+i.url_image" v-if="i.url_image" aspect-ratio="1" :src="Url+'/libraryImage/thumb/'+i.url_image"  class="img white--text" :class="'p'+x" :key="i"></v-img>
                                </a>
                            </template>
                        </div>

                    </template>
                </div>
            </div>
        </template>
        

        <template v-if="photoLoad">

            <div class="photos_mob d-none" >
                <v-skeleton-loader class=" p0" style="margin: 0 5px;" v-bind="attrs" type="image@2"></v-skeleton-loader>
                <v-skeleton-loader class=" p0" style="margin: 0 5px;" v-bind="attrs" type="image@2"></v-skeleton-loader>
                <v-skeleton-loader class=" p0" style="margin: 0 5px;" v-bind="attrs" type="image@2"></v-skeleton-loader>
            </div>
            
        </template>
        <template v-else>
            <div class="photos_mob d-none">
                <template v-for="i in photosData">
                    <a data-fancybox="gallery" :data-caption="i.title" :href="Url+'/libraryImage/thumb/'+i.url_image" :key="i">
                        <v-img aspect-ratio="1" width="100%" :src="Url+'/libraryImage/thumb/'+i.url_image" class="img white--text" 
                        :lazy-src="Url+'/libraryImage/small_thumb/'+i.url_image"
                        ></v-img>
                    </a>
                </template>
            </div>
        </template>
        

        <div v-if="getMore_btn" style="text-align: center;">
            <v-btn width="180" height="50" style="justify-content: center;" :loading="btnLoader" class="ma-2 tl" color="#145C5E" @click="getMore">
                <p class="btnT white--text mb-0 tl">{{ $t('showMore') }}</p>
            </v-btn>
        </div>

        


    </v-container>



    
</div>
</template>


<script>
import Axios from "axios";
// import fancyBox from 'vue-fancybox';
import { Fancybox} from "@fancyapps/ui";

import "@fancyapps/ui/dist/fancybox.css";
export default {
    data() {
        return {
            photosData: [],
            photoLoad: true,
            getMore_btn: true,
            attrs: {
                class: 'mb-6',
                boilerplate: false,
                elevation: 2,
            },
            photoPage: 1,
            btnLoader: false,
        }
    },
    components: {
    },
    directives: {
    },
    computed: {
    },
    watch: {
        photosData: function (t) {
            
        }
    },
    mounted() {
        document.title =this.$t("photoLibrary");
        this.iniFancy();
    }, 
    created () {
        this.getPhotos();
    },
    methods:{
        getPhotos() {
            Axios.get("libraryImage/")
            .then(d => {
                this.photoLoad = false;
                this.photosData = d.data.data;
                // console.log(d.data.data);
            });
            

        },
        photoSlice (data, index, silde) {
            // this.loader = 'loading';
            let start,end,step;
            step = (silde-1)*10; // edited from 7
            if (index === 1) {
                start = step+0;
                end = start+3;
            } else {
                start = step+(index*2)-1;
                end = start+2;
            }
            return data.slice(start, end)
        },
        cols (col) {
            let sm = [2,6,4]
            return sm[col]
        },
        photoSliceRow (data, start, step, slide) {
            var array = [];
            step = step -1;
            slide = slide -1;
            start = (10*slide) + start;
            let max = start + step;
            let j = -1;
            for(var i = start; i <= max; i++){
                j++;
                if (data[i]) {array[j] = data[i];}
            }
            return array;
        },

        photoSliceTab (data, index, slide) {
            let start,end,step;
            slide = slide -1;
            step = slide*10;
            start = step+(index*2)-2;
            end = start+2;
            return data.slice(start, end)
        },

        getMore() {
            this.btnLoader = true;
            var p = this.photoPage +1;
            Axios.get("libraryImage/?page=" + p, {})
                .then(d => {
                    this.btnLoader = false;
                    this.photoLoad = false;
                    this.photoPage++;
                    this.photosData = this.photosData.concat(d.data.data);
                    if (d.data.data.length < 10) {
                        this.getMore_btn = false;
                    }
                })
        },
        iniFancy () {
            Fancybox.bind('[data-fancybox="gallery"]', {
                dragToClose: false,
                Toolbar: false,
                closeButton: "top",
                Image: {
                zoom: false,
                },
                on: {
                    initCarousel: (fancybox) => {
                    const slide = fancybox.Carousel.slides[fancybox.Carousel.page];
                    fancybox.$container.style.setProperty("--bg-image",`url("${slide.$thumb.src}")`);
                    },
                    "Carousel.change": (fancybox, carousel, to, from) => {
                    const slide = carousel.slides[to];
                    fancybox.$container.style.setProperty("--bg-image", `url("${slide.$thumb.src}")`);
                    },
                },
            });
        },
    },
    
}
</script>
<style scoped>
    .v-skeleton-loader__image.v-skeleton-loader__bone { height: 100%; }
    .photos {
        width: 100%;
        margin: 0 auto;
        padding: 20px 0 0 0;
    }
    .title p {
        font-size: 38px;
        letter-spacing: normal;
        margin: 0;
    }
    .title {
        padding: 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
    }
    .photos_cont {
        max-width: 1260px;
        margin: 0 auto;
        border-top: solid thin #C4C4C4;
        padding-top: 25px;
    }

    .row3 {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
    }
    .photos_desktop .photos .column1 .p0 {
        width:41%; height:257px; margin: 0;
    }
    .photos_desktop .photos .column1 .p1 {
        width:56%; height:257px; margin: 0 10px 0 0;
    }
    .photos_desktop .photos .column1 .p2 {
        width:100%; height:333px; margin: 10px 0 0 0;
    }
    .photos_desktop .photos .column2 .p0 {
        width:100%; height:360px; margin-bottom: 10px;
    }
    .photos_desktop .photos .column2 .p1 {
        width:100%; height:230px; 
    }
    .photos_desktop .photos .column3 .p0 {
        width:100%; height:223px; margin-bottom: 10px;
    }
    .photos_desktop .photos .column3 .p1 {
        width:100%; height:367px; 
    }

    .pp {
        width: 100%;
        margin: 0 auto;
    }
    .rr {
        margin:5px; padding:0
    }
    .photos_desktop .dcolumn0 {
        flex: 15%;
        max-width: 15%;
    }
    .photos_desktop .dcolumn1 {
        flex: 47%;
        max-width: 47%;
    }
    .photos_desktop .dcolumn2 {
        flex: 35%;
        max-width: 35%;
    }
    .photos_desktop .r0 {
        width:100%; height:365px; 
    }

    .photos_desktop .r1 {
        width:100%; height:365px; 
    }

    .photos_desktop .r2 {
        width:100%; height:365px; 
    }

    .photos_desktop .photos .col {
        margin:5px; padding:0
    }
    .photos_desktop .photos .column1 {
        flex: 50%;
        max-width: 50%;
        display: flex;
        flex-wrap: wrap;
    }

    .photos_desktop .photos .column2 {
        flex: 22%;
        max-width: 22%;
    }

    .photos_desktop .photos .column3 {
        flex: 25%;
        max-width: 25%;
    }






    /* tab */
    .photos_tab .photos .column1 .p0 {
        width:100%; height:360px; margin-bottom: 10px;
    }
    .photos_tab .photos .column1 .p1 {
        width:100%; height:230px; 
    }
    .photos_tab .photos .column2 .p0 {
        width:100%; height:223px; margin-bottom: 10px;
    }
    .photos_tab .photos .column2 .p1 {
        width:100%; height:367px; 
    }
    .photos_tab .column1, .photos_tab .column2 {
        flex: 48%;
        max-width: 48%;
    }
    .photos_tab .column3, .photos_tab .column4, .photos_tab .column5 {
        flex: 31.5%;
        max-width: 31.3%;
    }
    .photos_tab .col {
        min-width: 20%;
    }
    .photos_tab .img {
        margin-bottom: 10px;
    }
    .photos_tab .photos .col {
        margin:5px; padding:0
    }



    /* Moble */
    .photos_mob .img {
        margin-bottom: 10px;
    }
    



    @media (max-width: 1083px) {
        .photos_desktop {
            display: none;
        }
        .photos_tab {
            display: block!important;
        }
    }

    @media (max-width: 550px) {
        .photos_tab {
            display: none!important;
        }
        .photos_mob {
            display: block!important;
        }
        .photos_mob .photos .column1 {
            flex: 100%;
            max-width: 100%;
            display: flex;
            flex-wrap: wrap;
        }
    }
    
</style>