<template>
<div class="news dirr">
    <div class="title"><p class="tl">{{ $t("newsAct") }}</p></div>
    <div class="Fgroup" v-if="impoNews_skeleton">
         <v-skeleton-loader  width="100%" min-width="100%" type="image,image,image"></v-skeleton-loader>
         <br>
        <div class="items" style="width:100%;">
            <v-layout row>
                <v-flex xs12 sm6 md4 lg4 style="padding:10px;">
                    <v-skeleton-loader width="100%" min-width="100%" type="image"></v-skeleton-loader>
                </v-flex>
                <v-flex xs12 sm6 md4 lg4 style="padding:10px;">
                    <v-skeleton-loader width="100%" min-width="100%" type="image"></v-skeleton-loader>
                </v-flex>
                <v-flex xs12 sm6 md4 lg4 style="padding:10px;">
                    <v-skeleton-loader width="100%" min-width="100%" type="image"></v-skeleton-loader>
                </v-flex>
            </v-layout>
        </div>
    </div>
  <div class="Fgroup" v-if="!impoNews_skeleton">
    <router-link v-for="i in NewsRes.slice(0,1)" :key="i" :to="'/news/'+i.id+'?lang='+$i18n.locale" class="Fitem">
        <div class="img">
            <v-img :lazy-src="Url+'/art_img/small_thumb/'+i.url_image"
                :src="Url+'/art_img/main_news/'+i.url_image" style="border-radius: 4px;height: 100%; width: 100%;">
            </v-img>
            <div class="title"><p class="tl ta">{{i.title}}</p></div>
        </div>
    </router-link>
        <div class="items">
            <v-layout row wrap>
                <v-flex xs12 sm6 md4 lg4 v-for="i in  NewsRes.slice(1,4)" :key="i" style="">
                    <router-link :to="'/news/' + i.id + '?&lang=' +$i18n.locale" class="Oitem">
                        <div class="img">
                            <v-img :lazy-src="Url+'/art_img/small_thumb/'+i.url_image"
                                :src="Url+'/art_img/thumb_new/'+i.url_image" style="border-radius: 4px;height: 100%; width: 100%;">
                            </v-img>
                        </div>
                        <div :class="'paregs ' + $store.state.direction">
                            <p class="tl ta">{{i.title|subStrtitle}}</p>
                            <div class="details"> 
                                <p class="tl">{{ i.date|FormatDate }}</p>
                            </div>
                        </div>
                    </router-link>
                </v-flex>
            </v-layout>
        </div>
    </div>
    <div class="Sgroup" v-if="!impoNews_skeleton">
        <div class="bcon d-none d-sm-flex">
            <div class="items">
                <v-layout row>
                    <v-flex xs12 sm12 md8 lg8 style="padding: 0 10px;">
                        <router-link v-for="i in NewsResMore" :key="i" :to="'/news/' + i.id + '?&lang=' +$i18n.locale" class="item" >
                            <div class="img">
                                <v-img :lazy-src="Url+'/art_img/small_thumb/'+i.url_image"
                                    :src="Url+'/art_img/thumb_new/'+i.url_image" style="border-radius: 4px;height: 100%; width: 100%;">
                                </v-img>
                            </div>
                            <div :class="'paregs ' + $store.state.direction">
                                <p class="tl ta">{{i.title|subStrtitle}}</p>
                                <p class="tx ta">{{i.desc|subStrtext2}}</p>
                                <div class="details">
                                    <div class="date">
                                        <span>
                                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_169_2060)">
                                            <path d="M16.8414 10.2516C17.3118 10.2516 17.6931 9.85253 17.6931 9.3602C17.6931 8.86786 17.3118 8.46875 16.8414 8.46875C16.3711 8.46875 15.9897 8.86786 15.9897 9.3602C15.9897 9.85253 16.3711 10.2516 16.8414 10.2516Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                            <path d="M18.8002 1.78289H17.693V0.891447C17.693 0.399101 17.3117 0 16.8413 0C16.371 0 15.9897 0.399101 15.9897 0.891447V1.78289H12.1145V0.891447C12.1145 0.399101 11.7333 0 11.2629 0C10.7925 0 10.4112 0.399101 10.4112 0.891447V1.78289H6.57868V0.891447C6.57868 0.399101 6.19738 0 5.72701 0C5.25663 0 4.87533 0.399101 4.87533 0.891447V1.78289H3.81074C1.93229 1.78289 0.404053 3.38251 0.404053 5.34868V19.2553C0.404053 21.2214 1.93229 22.8211 3.81074 22.8211H10.326C10.7964 22.8211 11.1777 22.422 11.1777 21.9296C11.1777 21.4373 10.7964 21.0382 10.326 21.0382H3.81074C2.87152 21.0382 2.1074 20.2384 2.1074 19.2553V5.34868C2.1074 4.3656 2.87152 3.56579 3.81074 3.56579H4.87533V4.45724C4.87533 4.94958 5.25663 5.34868 5.72701 5.34868C6.19738 5.34868 6.57868 4.94958 6.57868 4.45724V3.56579H10.4112V4.45724C10.4112 4.94958 10.7925 5.34868 11.2629 5.34868C11.7333 5.34868 12.1145 4.94958 12.1145 4.45724V3.56579H15.9897V4.45724C15.9897 4.94958 16.371 5.34868 16.8413 5.34868C17.3117 5.34868 17.693 4.94958 17.693 4.45724V3.56579H18.8002C19.7394 3.56579 20.5035 4.3656 20.5035 5.34868V10.4299C20.5035 10.9223 20.8848 11.3214 21.3552 11.3214C21.8256 11.3214 22.2069 10.9223 22.2069 10.4299V5.34868C22.2069 3.38251 20.6786 1.78289 18.8002 1.78289Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                            <path d="M17.0542 12.0345C14.2131 12.0345 11.9016 14.4539 11.9016 17.4278C11.9016 20.4017 14.2131 22.8211 17.0542 22.8211C19.8954 22.8211 22.2068 20.4017 22.2068 17.4278C22.2068 14.4539 19.8954 12.0345 17.0542 12.0345ZM17.0542 21.0382C15.1523 21.0382 13.605 19.4186 13.605 17.4278C13.605 15.437 15.1523 13.8174 17.0542 13.8174C18.9561 13.8174 20.5035 15.437 20.5035 17.4278C20.5035 19.4186 18.9561 21.0382 17.0542 21.0382Z" fill="#098486"/>
                                            <path d="M18.2892 16.5363H17.906V15.6003C17.906 15.108 17.5247 14.7089 17.0543 14.7089C16.5839 14.7089 16.2026 15.108 16.2026 15.6003V17.4278C16.2026 17.9201 16.5839 18.3192 17.0543 18.3192H18.2892C18.7596 18.3192 19.1409 17.9201 19.1409 17.4278C19.1409 16.9354 18.7596 16.5363 18.2892 16.5363Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                            <path d="M13.1366 10.2516C13.6069 10.2516 13.9883 9.85253 13.9883 9.3602C13.9883 8.86786 13.6069 8.46875 13.1366 8.46875C12.6662 8.46875 12.2849 8.86786 12.2849 9.3602C12.2849 9.85253 12.6662 10.2516 13.1366 10.2516Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                            <path d="M9.43175 14.1294C9.90212 14.1294 10.2834 13.7303 10.2834 13.238C10.2834 12.7457 9.90212 12.3465 9.43175 12.3465C8.96138 12.3465 8.58008 12.7457 8.58008 13.238C8.58008 13.7303 8.96138 14.1294 9.43175 14.1294Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                            <path d="M5.72692 10.2516C6.19728 10.2516 6.57859 9.85253 6.57859 9.3602C6.57859 8.86786 6.19728 8.46875 5.72692 8.46875C5.25655 8.46875 4.87524 8.86786 4.87524 9.3602C4.87524 9.85253 5.25655 10.2516 5.72692 10.2516Z" fill="#098486"/>
                                            <path d="M5.72692 14.1294C6.19728 14.1294 6.57859 13.7303 6.57859 13.238C6.57859 12.7457 6.19728 12.3465 5.72692 12.3465C5.25655 12.3465 4.87524 12.7457 4.87524 13.238C4.87524 13.7303 5.25655 14.1294 5.72692 14.1294Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                            <path d="M5.72692 18.0072C6.19728 18.0072 6.57859 17.6081 6.57859 17.1158C6.57859 16.6235 6.19728 16.2243 5.72692 16.2243C5.25655 16.2243 4.87524 16.6235 4.87524 17.1158C4.87524 17.6081 5.25655 18.0072 5.72692 18.0072Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                            <path d="M9.43175 18.0072C9.90212 18.0072 10.2834 17.6081 10.2834 17.1158C10.2834 16.6235 9.90212 16.2243 9.43175 16.2243C8.96138 16.2243 8.58008 16.6235 8.58008 17.1158C8.58008 17.6081 8.96138 18.0072 9.43175 18.0072Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                            <path d="M9.43175 10.2516C9.90212 10.2516 10.2834 9.85253 10.2834 9.3602C10.2834 8.86786 9.90212 8.46875 9.43175 8.46875C8.96138 8.46875 8.58008 8.86786 8.58008 9.3602C8.58008 9.85253 8.96138 10.2516 9.43175 10.2516Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_169_2060">
                                            <rect width="21.8028" height="22.8211" fill="white" transform="translate(0.404053)"/>
                                            </clipPath>
                                            </defs>
                                            </svg>
                                        </span>
                                        <!-- <p class="tl">{{ i.time|FormatDate }}</p> -->
                                        <p class="tl">{{ i.date|FormatDate }}</p>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                        <div class="btnCon" v-if="getMorenews">
                            <v-btn width="180" height="50" :loading="loading" class="ma-2 tl" style="align-self:center !important;" color="#145C5E" @click="showMore()">
                                <p class="btnT tl">{{ $t('showMore') }}</p>
                            </v-btn>
                        </div>
                    </v-flex>
                    <v-flex xs12 sm12 md4 lg4 class="d-none d-sm-flex" style="padding: 0 10px; flex-direction: column;">
                        <div class="quran">
                            <div class="borders">
                                <div class="tl"><img src="/assets/img/borders/mob/tl.svg"/></div>
                                <div class="tr"><img src="/assets/img/borders/mob/tr.svg"/></div>
                                <div class="bl"><img src="/assets/img/borders/mob/bl.svg"/></div>
                                <div class="br"><img src="/assets/img/borders/mob/br.svg"/></div>
                            </div>
                            <div class="flora"></div>
                            <div class="Con">
                                <div class="op">
                                    <p class="tl ta" >{{ $t("musfah") }}</p>
                                    <p class="tl ta" >{{ $t("ataba") }}</p>
                                    <p class="tl ta" >{{ $t("center") }}</p>
                                    <div class="btnCon">
                                        <v-btn width="245" height="50" class="ma-2 tl" style="align-self:center !important;" color="#145C5E">
                                            <p class="btnT tl">{{ $t('readQ') }}</p>
                                            <span class="btnI">
                                                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.11802 0.880978C2.0899 0.490474 2.3851 0.123401 2.74356 0.123401C3.9384 0.131211 5.1473 0.342083 6.23672 0.920028C7.05906 1.34958 7.78299 2.01344 8.29607 2.84912C8.41556 3.02094 8.52099 3.224 8.50693 3.45049C8.50693 7.1837 8.50693 10.9091 8.50693 14.6423C7.4386 13.4005 5.95558 12.6898 4.43743 12.4321C3.87514 12.3227 3.30584 12.2915 2.74356 12.2759C2.39213 12.2759 2.09693 11.901 2.12505 11.5105C2.11802 7.96471 2.11802 4.42675 2.11802 0.880978Z" fill="white"/>
                                                <path d="M12.6959 0.521723C13.5253 0.240562 14.3968 0.123411 15.2613 0.123411C15.6127 0.115601 15.915 0.490483 15.8869 0.880985C15.8869 4.42674 15.8869 7.9725 15.8869 11.5104C15.915 11.901 15.6198 12.2758 15.2684 12.2758C14.0946 12.2993 12.9068 12.4789 11.8033 12.9631C10.9388 13.338 10.1446 13.9081 9.50497 14.6423C9.50497 10.9091 9.50497 7.18369 9.50497 3.45049C9.49091 3.13809 9.68771 2.90378 9.83531 2.66167C10.5311 1.61513 11.5714 0.896605 12.6959 0.521723Z" fill="white"/>
                                                <path d="M0.508492 2.61486C0.70529 2.58362 0.909118 2.59924 1.11294 2.59924C1.11294 5.56705 1.11294 8.53486 1.11294 11.5105C1.09889 12.2056 1.45031 12.885 2.0126 13.1896C2.47648 13.4708 3.0247 13.3693 3.53076 13.4317C5.00675 13.5645 6.51085 14.08 7.6073 15.2359C5.3652 14.6423 3.01065 14.8453 0.782604 15.3842C0.508492 15.4545 0.206265 15.2983 0.0797518 15.0172C-0.011619 14.8297 0.00946658 14.611 0.00243805 14.4002C0.00243805 10.7295 0.00243805 7.05876 0.00243805 3.39586C-0.025676 3.0366 0.192208 2.68515 0.508492 2.61486Z" fill="white"/>
                                                <path d="M16.8849 2.59137C17.1309 2.60699 17.398 2.54451 17.637 2.66166C17.8759 2.78662 18.0165 3.0834 17.9954 3.37237C17.9954 7.12902 17.9954 10.8779 17.9954 14.6267C18.0235 15.0172 17.7143 15.3921 17.3558 15.3843C17.0747 15.3608 16.8006 15.2593 16.5265 15.2125C14.5093 14.7985 12.4007 14.6892 10.3906 15.2203C11.487 14.0644 12.9981 13.5489 14.4741 13.4161C14.9732 13.3537 15.5214 13.4552 15.9782 13.174C16.5335 12.8694 16.8919 12.1821 16.8779 11.4949C16.8919 8.53484 16.8849 5.56701 16.8849 2.59137Z" fill="white"/>
                                                </svg>
                                            </span>
                                        </v-btn>
                                        <v-btn width="245" height="50" class="ma-2 tl" style="align-self:center !important;" color="#145C5E">
                                            <p class="btnT tl">{{ $t('downloadQ') }}</p>
                                            <span class="btnI">
                                                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.12817 0.977657C7.13591 0.465542 7.55134 0.0139912 8.0202 0.00402486C8.67322 -0.00134162 9.32695 -0.00134162 9.97998 0.00402486C10.4495 0.0132245 10.8636 0.466309 10.872 0.978424C10.8783 2.56077 10.8727 4.14311 10.8748 5.72546C11.5883 5.73159 12.3032 5.71319 13.0167 5.73389C13.4567 5.77222 13.6634 6.44303 13.3372 6.76732C12.0326 8.26227 10.7244 9.75415 9.41763 11.2468C9.20745 11.5128 8.79413 11.5144 8.58465 11.2483C7.26806 9.74188 5.94584 8.24157 4.63486 6.73052C4.33963 6.39473 4.56879 5.75996 4.99406 5.73312C5.70403 5.71472 6.41469 5.73082 7.12536 5.72546C7.12747 4.14311 7.12185 2.56 7.12817 0.977657Z" fill="white"/>
                                                <path d="M0.950766 13.1418C1.09838 13.0935 1.25373 13.0851 1.40697 13.0843C6.35213 13.0858 11.2973 13.0843 16.2424 13.0851C16.5433 13.0897 16.8575 13.0536 17.1443 13.1763C17.6286 13.3672 17.9822 13.8954 17.9942 14.4581C18.0026 14.8245 18.0237 15.2132 17.8648 15.549C17.6511 16.042 17.1626 16.3693 16.6621 16.3555C11.5532 16.3563 6.44421 16.3563 1.33457 16.3555C0.819317 16.3693 0.318123 16.0182 0.114974 15.5022C-0.0249099 15.1757 -0.00100979 14.8061 0.00601957 14.4558C0.0172666 13.8548 0.422158 13.302 0.950766 13.1418Z" fill="white"/>
                                                </svg>
                                            </span>
                                        </v-btn>
                                    </div>
                                </div>
                                <div class="img"></div>
                            </div>
                        </div>
                    </v-flex>
                </v-layout>
            </div>
        </div>
        <div class="mcon d-flex d-sm-none">
            <router-link v-for="i in NewsResMore" :key="i" :to="'/news/' + i.id + '?&lang=' +$i18n.locale" class="Fitem">
                <div class="img">
                    <v-img :lazy-src="Url+'/art_img/small_thumb/'+i.url_image"
                        :src="Url+'/art_img/thumb_new/'+i.url_image" style="border-radius: 4px;height: 100%; width: 100%;">
                    </v-img>
                </div>
                <p :class="'tl ta ' + $store.state.direction">{{i.title}}</p>
            </router-link>
            <div class="btnCon" v-if="getMorenews">
                <v-btn width="180" height="50" :loading="loading" class="ma-2 tl" style="align-self:center !important;" color="#145C5E" @click="showMore()">
                    <p class="btnT tl">{{ $t('showMore') }}</p>
                </v-btn>
            </div>
        </div>
    </div>
</div>
</template>


<script>
import axios from "axios";
export default {
    data() {
        return {
            NewsRes : [],
            NewsResMore : [],
            NewsPage : 1,
            getMorenews : true,
            loader: null,
            loading: false,
            impoNews_skeleton: true,
        }
    },
    components: {
    },
    directives: {
    },
    computed: {
    },
    watch: {
      loader () {
        const l = this.loader
        this[l] = !this[l]
        setTimeout(() => (this[l] = false), 2000)
        this.loader = null
      },
    },
    mounted() {
        document.title =this.$t("newsAct");
    }, 
    created () {
        this.getimpoNews();
    },
    methods:{
        getimpoNews () {
            this.loader = 'loading';
            axios.get("articles/GetByCat/1/7?page=" + this.NewsPage , {
            })
            .then(res => {
                this.NewsPage ++;
                this.NewsRes = res.data.data;
                this.NewsResMore = this.NewsResMore.concat(this.NewsRes.slice(4,7));
                setTimeout(() => this.impoNews_skeleton = false, 500);
                //console.log(res.data.data);
            })
            .catch(() => {
            });
        },
        showMore () {
            axios.get("articles/GetByCat/1/7?page=" + this.NewsPage, {
            })
            .then(res => {
                this.NewsPage ++;
                this.NewsResMore = this.NewsResMore.concat(res.data.data);
                if (res.data.data.length < 7) {this.getMorenews = false;}
                //console.log(res.data.data);
            })
            .catch(() => {
            });
        },
    },
    filters: {
        subStrtitle: function (string) {if (string != null) {return string.substring(0, 60) + '...';}},
        subStrtext: function (string) {if (string != null) {return string.substring(0, 80) + '...';}},
        subStrtext2: function (string) {if (string != null) {return string.substring(0, 150) + '...';}},
        FormatDate: function (unixTime) {
            let d = new Date(unixTime*1000);
            let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
            let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
            let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
            return (`${da}-${mo}-${ye}`);
        },    
    },
}
</script>