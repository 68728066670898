<template>
<div class="video_inner dirr">
<div class="videoCon">
    <v-layout row v-if="video_skeleton">
        <v-flex xs12 sm8 md8 lg8 style="padding: 0 10px;">
            <br><br><br>
            <v-skeleton-loader  width="800" min-width="800" type="image, image"></v-skeleton-loader><br>
            <v-skeleton-loader  width="800" min-width="800" type="sentences, sentences paragraph, sentences, sentences, sentences paragraph, sentences"></v-skeleton-loader>
        </v-flex>
        <v-flex xs12 sm4 md4 lg4 style="padding: 0 10px;">
            <div class="items">
                <br><br><br>
                <v-skeleton-loader width="350" min-width="350" type="card"></v-skeleton-loader><br>
                <v-skeleton-loader width="350" min-width="350" type="card"></v-skeleton-loader><br>
                <v-skeleton-loader width="350" min-width="350" type="card"></v-skeleton-loader><br>
            </div>
        </v-flex>
    </v-layout>
    <v-layout row v-if="!video_skeleton">
        <v-flex xs12 sm8 md8 lg8 style="padding: 0 10px;">
            <router-link class="tl videoLib" :to="'/video?lang=' + $i18n.locale">{{ $t("videoLib") }}</router-link>
            <div class="vid">
                <div class="vidCon">
                    <video ref="videoPlayer" class="video-js vjs-big-play-centered" :poster="Url+'/imageVidoes/img_main/'+videosRes.image_url"></video>
                </div>
            </div>
            <div class="TitleShare">
                <p class="tl">{{ videosRes.title }}</p>
                <a href="" class="share">
                    <p class="tl">{{ $t("share") }}</p>
                    <span>
                        <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_239_551)">
                        <path d="M21.5769 18.5769C20.1911 18.5769 18.9174 19.0308 17.9119 19.7899L11.6297 16.0714C11.8158 15.2744 11.8158 14.4486 11.6297 13.6516L17.9119 9.93308C18.9174 10.6922 20.1911 11.1462 21.5769 11.1462C24.8269 11.1462 27.4615 8.65098 27.4615 5.57308C27.4615 2.49517 24.8269 0 21.5769 0C18.327 0 15.6923 2.49517 15.6923 5.57308C15.6923 5.98862 15.7407 6.39336 15.8318 6.78296L9.54963 10.5015C8.54415 9.74238 7.27044 9.28846 5.88461 9.28846C2.63465 9.28846 0 11.7836 0 14.8615C0 17.9394 2.63465 20.4346 5.88461 20.4346C7.27044 20.4346 8.54415 19.9807 9.54963 19.2216L15.8318 22.9401C15.739 23.3374 15.6922 23.7431 15.6923 24.15C15.6923 27.2279 18.327 29.7231 21.5769 29.7231C24.8269 29.7231 27.4615 27.2279 27.4615 24.15C27.4615 21.0721 24.8269 18.5769 21.5769 18.5769Z" fill="#716F6F"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_239_551">
                        <rect width="27.4615" height="29.7231" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>
                    </span>
                </a>
            </div>
            <div class="details2 dirr">
                <!-- <p class="tl">{{ videosRes[0].time|FormatDate }}</p> -->
                <!-- <span>
                    <svg width="5" height="4" viewBox="0 0 5 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <ellipse opacity="0.7" rx="2.1118" ry="2" transform="matrix(-1 0 0 1 2.11183 2)" fill="black"/>
                    </svg>
                </span> -->
                <!-- <p class="tl">{{videosRes[0].view}} {{ $t("view") }}</p> -->
            </div>
            <div class="description">
                <p class="tl">{{ $t("Viddescription") }}</p>
                <p class="tx">{{ videosRes.desc }}</p>
            </div>
        </v-flex>
        <v-flex xs12 sm4 md4 lg4 style="padding: 0 10px;">
            <div class="items">
                <p class="tl videoLib">{{ $t("latestVid") }}</p>
                <router-link v-for="i in relatedVid" :key="i" :to="'/video/' + i.id + '?&lang=' +$i18n.locale" class="item" >
                    <div class="img">
                        <!-- <v-img :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+i.image_url"
                            :src="'https://alkafeel.net/news/mcroped96/260/'+i.image_url" style="height: 100%; width: 100%; border-radius: 4px;">
                        </v-img> -->
                        <v-img :lazy-src="Url+'/imageVidoes/small_thumb/'+i.image_url"
                            :src="Url+'/imageVidoes/slid_thumb/'+i.image_url" style="border-radius: 4px; width: 100% !important; height: 100%;">
                        </v-img>
                        <div class="icon">
                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.75">
                            <circle cx="24" cy="24" r="24" fill="#098486"/>
                            <path d="M34.5 23.134C35.1667 23.5189 35.1667 24.4811 34.5 24.866L19.5 33.5263C18.8333 33.9112 18 33.4301 18 32.6603V15.3397C18 14.5699 18.8333 14.0888 19.5 14.4737L34.5 23.134Z" fill="white"/>
                            </g>
                            </svg>
                        </div>
                    </div>
                    <div :class="'paregs ' + $store.state.direction">
                        <p class="tl ta">{{i.title|subStrtitle}}</p>
                        <!-- <div class="details dirr">
                            <p class="tl">{{ i.time|FormatDate }}</p>
                            <span>
                                <svg width="5" height="4" viewBox="0 0 5 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <ellipse opacity="0.7" rx="2.1118" ry="2" transform="matrix(-1 0 0 1 2.11183 2)" fill="black"/>
                                </svg>
                            </span>
                            <p class="tl">{{i.view}} {{ $t("view") }}</p>
                        </div> -->
                    </div>
                </router-link>
            </div>
        </v-flex>
    </v-layout>
</div>
</div>
</template>


<script>
import Axios from "axios";
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css';
export default {
    name: "videoPlayer",
    data() {
    return {
        videosRes: [],
        relatedVid : [],
        video_skeleton : true,
        player: null,
        videoOptions: {
            autoplay: true,
            controls: true,
            fill: true,
            sources: [{ src:'', type: "video/mp4"}],
        },
    }
    },
    components: {
    },
    directives: {
    },
    computed: {
    },
    watch: {
        '$route' () {
            window.scrollTo(0, 0);
            //this.getvideos();
            setTimeout(() => {
                this.player.src({ type: "video/mp4", src: this.Url+"/video_vid/"+this.videosRes.video_url });
                this.player.poster(this.Url+'/imageVidoes/img_main/'+this.videosRes.image_url);
                this.$refs.videoPlayer.load();
                this.play_player();
            }
            , 500);
        },
    },
    mounted() {
        document.title =this.$t("videoLib");
    }, 
    created () {
        this.getvideos();
    },
    methods:{
        getvideos () {
            Axios.get("videos/"+this.$route.params.id, {
            })
            .then(res => {
                this.video_skeleton = false;
                this.videosRes = res.data.data;
                this.videoOptions.sources[0].src = this.Url+"/video_vid/"+this.videosRes.video_url;
                //this.player.poster(this.Url+'/imageVidoes/img_main/'+this.videosRes.image_url);
                setTimeout(() => this.play_player(), 500);
                this.getrelated();
            })
            .catch(() => {
            });
        },
        play_player () {
            this.player = videojs(this.$refs.videoPlayer, this.videoOptions, function onPlayerReady() {})
        },
        getrelated () {
            Axios.get("videos/getlimit/3?page=1" , {
            })
            .then(res => {
                this.relatedVid = res.data.data;
                //console.log(res.data.data);
            })
            .catch(() => {
            });
        },
    },
    filters: {
        subStrtitle: function (string) {if (string != null) {return string.substring(0, 85) + '...';}},
        subStrtext: function (string) {if (string != null) {return string.substring(0, 110) + '...';}},
        FormatDate: function (unixTime) {
            let d = new Date(unixTime*1000);
            let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
            let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
            let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
            return (`${da}-${mo}-${ye}`);
        },
    },
}
</script>