<template>
<div class="library">
    <div class="title"><p class="tl">{{ $t("Elibrary") }}</p></div>
    <v-container class="library_cont">
        

        <v-layout row ma-5>
            <!-- <div class="serv2" style="padding-top:12px"><svg viewBox="0 0 27 20"><use xlink:href="/assets/img/books/book.svg#view"></use></svg></div> -->
            <!-- <span class="t1 sec_title">تاريخ</span> -->
            <template v-if="latestLoad">
                <v-skeleton-loader class="main_card" style="margin: 0 5px;" v-bind="attrs" type="text"></v-skeleton-loader>
            </template>
            <template v-else>
                <p class="book_det t1">{{detail.title}}</p>
            </template>
        </v-layout>

            
        <v-layout row py3 class="d_align">
            <v-flex xl3 md3 sm6 xs12>
                <template v-if="latestLoad">
                    <v-skeleton-loader class="main_card" style="margin: 0 5px;" v-bind="attrs" type="image"></v-skeleton-loader>
                </template>
                <template v-else>
                    <v-card flat light class="main_card">
                       

                        <v-img aspect-ratio="1" class="main_img" height="375"
                        :lazy-src="Url+'/art_img/small_thumb/'+detail.image_url"
                                                        :src="Url+'/imageBooks/thumb_main/'+detail.image_url"
                        
                        
                        ></v-img>
                    </v-card>
                </template>
            </v-flex>

            <v-flex xl5 md5 sm6 xs12 pa-2>
                <template v-if="latestLoad">
                    <v-skeleton-loader style="margin: 0 5px;" height="100%" v-bind="attrs" type="article, actions"></v-skeleton-loader>
                </template>
                <template v-else>
                    <ul class="book_list">
                        
                        <li class="book_det t1" v-if="detail.author != null">{{$t('for_auth')}} {{detail.author}}</li>
                        <li class="book_det t1"> {{detail.cat_title}}</li>
                        <!-- <li class="book_det t1">{{$t('printed_year')}} {{items[0].year}}</li>
                        <li class="book_det t1">{{$t('book_type')}} {{items[0].type}}</li>
                        <li class="book_det t1">{{$t('book_size')}} {{items[0].size}}</li>
                        <li class="book_det t1">{{$t('book_pages')}} {{items[0].pages}}</li>
                        <li class="book_det t1">{{$t('book_down')}} {{items[0].down}}</li> -->
                    </ul>
                    <div style="margin-top:15px">                    
                        <v-btn :href="'/bookspdf/'+detail.book_url" download elevation="0" dark color="rgba(20, 92, 94,1)">{{$t('book_download')}}
                            <div class="serv" style="padding-top:5px"><svg viewBox="0 0 26 25"><use xlink:href="/assets/img/books/down.svg#view"></use></svg></div>
                        </v-btn>
                        <v-btn class="hidden-sm-and-down" :href="'/bookspdf/'+detail.book_url" target="_blank" elevation="0" dark color="rgba(20, 92, 94,1)">{{$t('book_view')}}
                            <div class="serv" style="padding-top:5px"><svg viewBox="0 0 26 25"><use xlink:href="/assets/img/books/view.svg#view"></use></svg></div>
                        </v-btn>
                    </div>
                </template>
            </v-flex>


            <v-flex xl4 md4 sm12 xs12 style="background: #C4C4C4" pa-2 class="hidden-sm-and-down">
                    <template v-if="latestLoad">
                        <v-skeleton-loader height="100%" v-bind="attrs" type="article"></v-skeleton-loader>
                    </template>
                    <template v-else>
                        <p class="tl" style="font-size:22px">{{$t('related_books')}}</p>
                        <ul class="related_list">
                            <template v-for="item in related" >
                                <router-link style="text-decoration: none;" :to="'/library/'+item.id" :key="item">
                                    <li class="related_det t1">{{item.title.substring(0,30)+".."}}</li>
                                </router-link>
                            </template>
                        </ul>
                    </template>
            </v-flex>
        </v-layout>
        
        <v-layout row mt-6>
            <span class="sec_title" style="padding-top: 10px; margin: 0 10px">{{$t('share_book')}}</span>
            <a :href="'https://wa.me/?text='+baseUrl" target="_blank">
                <div class="socials" style="padding-top:12px"><svg viewBox="0 0 32 32"><use xlink:href="/assets/img/books/whatsapp.svg#view"></use></svg></div>
            </a>

            <a :href="'https://telegram.me/share/?url='+baseUrl" target="_blank">
                <div class="socials" style="padding-top:12px"><svg viewBox="0 0 32 32"><use xlink:href="/assets/img/books/telegram.svg#view"></use></svg></div>
            </a>
            
            <a :href="'https://twitter.com/intent/tweet?text='+baseUrl" target="_blank" class="twitter">
                <div class="socials" style="padding-top:12px"><svg viewBox="0 0 32 32"><use xlink:href="/assets/img/books/twitter.svg#view"></use></svg></div>
            </a>
            
            <a :href="'https://www.facebook.com/sharer/sharer.php?u='+baseUrl" target="_blank">
                <div class="socials" style="padding-top:12px"><svg viewBox="0 0 32 32"><use xlink:href="/assets/img/books/facebook.svg#view"></use></svg></div>
            </a>
        </v-layout>

        <v-layout row mt-10>
            <p class="tl" style="font-size:22px">{{$t('latest_books')}}</p>
        </v-layout>
        

        <v-layout row pt-3 px-3 >

            <v-flex>
                <v-layout row px-1 py-2 class="centered">
                    <template v-for="item in latest.slice(0, 6)">
                        
                            <v-card flat :to="'/library/'+item.id" height="296" class="latest_items" width="185" :key="item">
                                <v-img height="232" :src="'https://alkafeel.net/publications/img/200/'+item.image_url"></v-img>
                                <v-card-subtitle class="sub" v-if="item.title != ''"  :title="item.title"><p class="t1">{{item.title.substring(0,30)+".."}}</p></v-card-subtitle>
                            </v-card>
                            
                    </template>
                </v-layout>
            </v-flex>

        </v-layout>    

    </v-container>



    
</div>
</template>


<script>
import pdf from 'pdfvuer'
import Axios from "axios";
export default {
    data() {
        return {
            items: [],
            latest: [],
            detail: [],
            related: [],
            baseUrl: '',
            attrs: {
                class: 'mb-6',
                boilerplate: false,
                elevation: 2,
            },
            latestLoad: true
        }
    },
    components: {
        pdf
    },
    directives: {
    },
    computed: {
    },
    watch: {
        '$route' () {
            // window.scrollTo(0, 0);
            this.getDetails();
        },
        detail: function (t1) {
            // console.log(t1.cat_id);
            this.getRelated (t1.cat_id); 
        }
    },
    updated() {
        this.baseUrl = 'https://alkafeel.net/holy_quran_center'+window.location.pathname+'?lang='+this.$i18n.locale;
    },
    mounted() {
        document.title =this.$t("Elibrary");
    }, 
    created () {
        this.getDetails();
        this.getLatest();
        // this.getLibrary();
    },
    methods:{
        getDetails () {
            Axios.get("books/"+this.$route.params.id)
            .then(d => 
            {
                this.latestLoad = false;
                this.detail = d.data.data;
                // console.log(this.detail);
            });
        },
        getRelated(cat) {
            Axios.get("books/getByCat/"+cat+"/6")
            .then(d => 
            {
                // this.latestLoad = false;
                // this.libraryRes  = d.data['sections'];
                this.related = d.data.data;
            });
        },
        getLatest () {
            Axios.get("books")
            .then(d => 
            {
                this.latestLoad = false;
                // this.libraryRes  = d.data['sections'];
                this.latest = d.data.data;
            });
        },
        // getLibrary() {
        //     Axios.get("https://alkafeel.net/main_bg/data/Holy_quran_library_test.json")
        //     .then(d => 
        //     {
        //         this.items  = d.data['items'];
        //     });
        // },
    },
    
}
</script>
<style scoped>
    .centered {
        justify-content: center;
    }
    .book_list li::before {
        content: '■';
        font-size: 10px;
        padding: 0 10px;
    }
    .book_list {
        list-style: none;
    }
    .book_det {
        font-size: 22px;
        line-height: 35px;
        font-weight: bolder;
    }
    .related_list li::before {
        content: '●';
        color: #145C5D;
        font-size: 16px;
        padding: 0 10px;
    }
    .related_list {
        list-style: none;
        line-height: 35px;
        font-weight: bold;
    }
    .related_det {
        font-size: 18px;
        /* text-decoration: none; */
        color:#000
    }
    .serv {
        fill:#FFF;
        width: 25px;
        height: 25px;
        margin: 0 auto 5px;
    }
    .serv2 {
        fill:#098486;
        width: 25px;
        height: 30px;
    }
    .socials {
        width: 32px;
        height: 32px;
        margin: 0 5px;
    }
    .sec_title {
        font-size: 22px;
        font-weight: bold;
        padding: 5px 15px;
        color: #737373;
    }
    .library {
        width: 100%;
        margin: 0 auto;
        padding: 20px 0 0 0;
    }
    .title p {
        font-size: 38px;
        letter-spacing: normal;
        margin: 0;
    }
    .title {
        padding: 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
    }
    .library_cont {
        max-width: 1260px;
        margin: 0 auto;
        border-top: solid thin #C4C4C4;
        padding-top: 25px;
    }
    .sub {
        color:#000; padding: 10px 0 0 0;
    }
    .main_img {
        height: 89%;
        width: 95%;
    }
    .main_card {
        height: 100%;
        width: 100%;
    }
    .latest_items {
        margin: 5px;
    }
    .latest_items2 {
        margin: 10px;
    }
    .d_align {
        padding-right: inherit;
    }
    @media (max-width: 490px) {
        /* .latest_items, .latest_items2 {
            flex:40%
        } */
    }


</style>
