<template>
<div class="video dirr">
<div class="video">
    <div class="Fgroup" v-if="video_skeleton">
        <div class="bcon d-none d-sm-flex">
            <div class="videoCon">
                <div class="Fitem" v-for="i in videosRes.slice(0,1)" :key="i">
                <router-link :to="'/video/' + i.id + '?&lang=' +$i18n.locale">
                    <v-layout row>
                        <v-flex xs12 sm5 md3 lg3 style="padding: 0 10px;">
                            <v-skeleton-loader  width="300" min-width="300" type="paragraph, paragraph, paragraph, sentences, paragraph, sentences paragraph, sentences paragraph, sentences, sentences paragraph, sentences"></v-skeleton-loader>
                        </v-flex>
                        <v-flex xs12 sm7 md9 lg9 style="padding: 0 10px;">
                            <v-skeleton-loader  width="800" min-width="800" type="image"></v-skeleton-loader>
                            <v-skeleton-loader  width="800" min-width="800" type="card"></v-skeleton-loader>        
                        </v-flex>
                    </v-layout>
                </router-link>
                </div>
                <div class="items">
                    <v-layout row>
                        <v-flex xs12 sm3 md3 lg3 style="padding: 0 10px;" class="item">
                            <v-skeleton-loader  width="250" min-width="250" type="card"></v-skeleton-loader>  
                        </v-flex>
                        <v-flex xs12 sm3 md3 lg3 style="padding: 0 10px;" class="item">
                            <v-skeleton-loader  width="250" min-width="250" type="card"></v-skeleton-loader>  
                        </v-flex>
                        <v-flex xs12 sm3 md3 lg3 style="padding: 0 10px;" class="item">
                            <v-skeleton-loader  width="250" min-width="250" type="card"></v-skeleton-loader>  
                        </v-flex>
                        <v-flex xs12 sm3 md3 lg3 style="padding: 0 10px;" class="item">
                            <v-skeleton-loader  width="250" min-width="250" type="card"></v-skeleton-loader>  
                        </v-flex>
                    </v-layout>
                </div>
            </div>
        </div>
    </div>
    <div class="Fgroup" v-if="!video_skeleton">
        <div class="bcon d-none d-sm-flex">
            <div class="videoCon">
                <div class="Fitem" v-for="i in videosRes.slice(0,1)" :key="i">
                <router-link :to="'/video/' + i.id + '?&lang=' +$i18n.locale">
                    <v-layout row>
                        <v-flex xs12 sm5 md3 lg3 style="padding: 0 10px;">
                            <p class="tl videoLib">{{ $t("videoLib") }}</p>
                            <p class="tx">{{ i.desc|subStrvidtext }}</p>
                            <router-link :to="'/video/?&lang=' +$i18n.locale" class="more d-none">
                                <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12" r="12" fill="#098486"/>
                                    <path d="M16.5 11.134C17.1667 11.5189 17.1667 12.4811 16.5 12.866L10.5 16.3301C9.83333 16.715 9 16.2339 9 15.4641V8.5359C9 7.7661 9.83333 7.28497 10.5 7.66987L16.5 11.134Z" fill="white"/>
                                    </svg>
                                </span>
                                <p class="tl">{{ $t("moreVid") }}</p>
                            </router-link>
                        </v-flex>
                        <v-flex xs12 sm7 md9 lg9 style="padding: 0 10px;">
                            <div class="img">
                                <v-img :lazy-src="Url+'/imageVidoes/small_thumb/'+i.image_url"
                                    :src="Url+'/imageVidoes/img_main/'+i.image_url" style="border-radius: 4px; width: 100% !important; height: 100%;">
                                </v-img>
                                <div class="bkg"></div>
                                <div class="iconTitle">
                                    <div class="icon">
                                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="32" cy="32" r="32" fill="#098486"/>
                                        <path d="M46.5 31.134C47.1667 31.5189 47.1667 32.4811 46.5 32.866L25.5 44.9904C24.8333 45.3753 24 44.8942 24 44.1244L24 19.8756C24 19.1058 24.8333 18.6247 25.5 19.0096L46.5 31.134Z" fill="white"/>
                                        </svg>
                                    </div>
                                    <p class="tl">{{ i.title }}</p>
                                </div>
                            </div>
                        </v-flex>
                    </v-layout>
                </router-link>
                </div>
                <div class="items">
                    <v-layout row>
                        <v-flex xs12 sm3 md3 lg3 v-for="i in videosRes.slice(1,5)" :key="i" style="padding: 0 10px;" class="item">
                            <router-link :to="'/video/' + i.id + '?&lang=' +$i18n.locale">
                                <div class="img">
                                    <v-img :lazy-src="Url+'/imageVidoes/small_thumb/'+i.image_url"
                                        :src="Url+'/imageVidoes/slid_thumb/'+i.image_url" style="border-radius: 4px; width: 100% !important; height: 100%;">
                                    </v-img>
                                    <div class="icon">
                                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="0.75">
                                        <circle cx="24" cy="24" r="24" fill="#098486"/>
                                        <path d="M34.5 23.134C35.1667 23.5189 35.1667 24.4811 34.5 24.866L19.5 33.5263C18.8333 33.9112 18 33.4301 18 32.6603V15.3397C18 14.5699 18.8333 14.0888 19.5 14.4737L34.5 23.134Z" fill="white"/>
                                        </g>
                                        </svg>
                                    </div>
                                </div>
                                <p class="tl ta">{{i.title|subStrtitle}}</p>
                                <div class="details dirr">
                                    <!-- <p class="tl">{{ i.time|FormatDate }}</p> -->
                                    <!-- <span>
                                        <svg width="5" height="4" viewBox="0 0 5 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <ellipse opacity="0.7" rx="2.1118" ry="2" transform="matrix(-1 0 0 1 2.11183 2)" fill="black"/>
                                        </svg>
                                    </span> -->
                                    <!-- <p class="tl">{{i.view}} {{ $t("view") }}</p> -->
                                </div>
                            </router-link>
                        </v-flex>
                    </v-layout>
                </div>
            </div>
        </div>
        <div class="mcon d-flex d-sm-none">
            <p class="tl videoLib">{{ $t("videoLib") }}</p>
            <div class="items">
                <v-layout row>
                    <v-flex xs12 sm4 md3 lg3 v-for="i in videosRes" :key="i" style="padding: 15px 10px;">
                        <router-link :to="'/video/' + i.id + '?&lang=' +$i18n.locale" class="item" >
                            <div class="img">
                                <v-img :lazy-src="Url+'/imageVidoes/small_thumb/'+i.image_url"
                                    :src="Url+'/imageVidoes/slid_thumb/'+i.image_url" style="border-radius: 4px; width: 100% !important; height: 100%;">
                                </v-img>
                                <div class="icon">
                                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.75">
                                    <circle cx="24" cy="24" r="24" fill="#098486"/>
                                    <path d="M34.5 23.134C35.1667 23.5189 35.1667 24.4811 34.5 24.866L19.5 33.5263C18.8333 33.9112 18 33.4301 18 32.6603V15.3397C18 14.5699 18.8333 14.0888 19.5 14.4737L34.5 23.134Z" fill="white"/>
                                    </g>
                                    </svg>
                                </div>
                            </div>
                            <div :class="'paregs ' + $store.state.direction">
                                <p class="tl ta">{{i.title|subStrtitle3}}</p>
                                <div class="details dirr">
                                    <!-- <p class="tl">{{ i.time|FormatDate }}</p> -->
                                    <!-- <span>
                                        <svg width="5" height="4" viewBox="0 0 5 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <ellipse opacity="0.7" rx="2.1118" ry="2" transform="matrix(-1 0 0 1 2.11183 2)" fill="black"/>
                                        </svg>
                                    </span> -->
                                    <!-- <p class="tl">{{i.view}} {{ $t("view") }}</p> -->
                                </div>
                            </div>
                        </router-link>
                    </v-flex>
                </v-layout>
            </div>
        </div>
    </div>
    <div class="Sgroup" v-if="!video_skeleton">
        <div class="items">
        <v-layout row>
            <v-flex xs12 sm4 md3 lg3 v-for="i in videosResMore" :key="i" style="padding: 15px 10px;">
                <router-link :to="'/video/' + i.id + '?&lang=' +$i18n.locale" class="item" >
                    <div class="img">
                        <v-img :lazy-src="Url+'/imageVidoes/small_thumb/'+i.image_url"
                            :src="Url+'/imageVidoes/slid_thumb/'+i.image_url" style="border-radius: 4px; width: 100% !important; height: 100%;">
                        </v-img>
                        <div class="icon">
                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.75">
                            <circle cx="24" cy="24" r="24" fill="#098486"/>
                            <path d="M34.5 23.134C35.1667 23.5189 35.1667 24.4811 34.5 24.866L19.5 33.5263C18.8333 33.9112 18 33.4301 18 32.6603V15.3397C18 14.5699 18.8333 14.0888 19.5 14.4737L34.5 23.134Z" fill="white"/>
                            </g>
                            </svg>
                        </div>
                    </div>
                    <div :class="'paregs ' + $store.state.direction">
                        <p class="tl ta">{{i.title|subStrtitle3}}</p>
                        <div class="details dirr">
                            <!-- <p class="tl">{{ i.time|FormatDate }}</p> -->
                            <!-- <span>
                                <svg width="5" height="4" viewBox="0 0 5 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <ellipse opacity="0.7" rx="2.1118" ry="2" transform="matrix(-1 0 0 1 2.11183 2)" fill="black"/>
                                </svg>
                            </span> -->
                            <!-- <p class="tl">{{i.view}} {{ $t("view") }}</p> -->
                        </div>
                    </div>
                </router-link>
            </v-flex>
        </v-layout>
        <div class="btnCon" v-if="getMoreVid">
            <v-btn width="180" height="50" :loading="loading" class="ma-2 tl" style="align-self:center !important;" color="#145C5E" @click="showMore()">
                <p class="btnT tl">{{ $t('showMore') }}</p>
            </v-btn>
        </div>
        </div>
    </div>
</div>
</div>
</template>


<script>
import Axios from "axios";
export default {
    name: "videos",
    data() {
    return {
        videosRes: [],
        videosResMore: [],
        getMoreVid: true,
        loader: null,
        loading: false,
        videoPage: 1,
        video_skeleton : true,
    }
    },
    components: {
    },
    directives: {
    },
    computed: {
    },
    watch: {
      loader () {
        const l = this.loader
        this[l] = !this[l]
        setTimeout(() => (this[l] = false), 2000)
        this.loader = null
      },
    },
    mounted() {
        document.title =this.$t("videoLib");
    }, 
    created () {
        this.getvideos();
    },
    methods:{
        getvideos () {
            this.loader = 'loading';
            Axios.get("videos/getlimit/8?page=" + this.videoPage , {
            })
            .then(res => {
                this.videoPage ++;
                this.videosRes = res.data.data;
                setTimeout(() => this.video_skeleton = false, 500);
                if (this.videosRes.length < 8) {this.getMoreVid = false;}
                else {this.showMore ();}
            })
            .catch(() => {
            });
        },
        showMore () {
            Axios.get("videos/getlimit/8?page=" + this.videoPage, {
            })
            .then(res => {
                this.videoPage ++;
                this.videosResMore = this.videosResMore.concat(res.data.data);
                if (res.data.data.length < 8) {this.getMoreVid = false;}
            })
            .catch(() => {
            });
        },
    },
    filters: {
        subStrtitle: function (string) {if (string != null) {return string.substring(0, 55) + '...';}},
        subStrtitle2: function (string) {if (string != null) {return string.substring(0, 80) + '...';}},
        subStrtitle3: function (string) {if (string != null) {return string.substring(0, 65) + '...';}},
        subStrtext: function (string) {if (string != null) {return string.substring(0, 110) + '...';}},
        subStrvidtext: function (string) {if (string != null) {return string.substring(0, 200) + '...';}},
        FormatDate: function (unixTime) {
            let d = new Date(unixTime*1000);
            let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
            let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
            let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
            return (`${da}-${mo}-${ye}`);
        },
    },
}
</script>