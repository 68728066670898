<template>
<div class="quran dirr">
    <div class="bcon d-none d-sm-flex">
        <div class="Fgroup" v-if="skeleton">
            <div class="items">
                <v-layout row>
                    <v-flex v-for="n in 2" :key="n" xs12 sm6 md4 lg4 style="padding: 0 15px;">
                        <v-skeleton-loader width="380" min-width="380" type="image, card"></v-skeleton-loader><br>
                    </v-flex>
                    <v-flex class="d-sm-none d-md-flex" xs12 sm6 md4 lg4 style="padding: 0 15px;">
                        <v-skeleton-loader width="380" min-width="380" type="image, card"></v-skeleton-loader><br>
                    </v-flex>
                </v-layout>
            </div>
        </div>
        <div class="Fgroup" v-if="!skeleton">
            <div class="items">
                <p class="tl">{{ title }}</p>
                <v-layout row>
                    <v-flex xs12 sm6 md4 lg4 v-for="i in quranRes.slice(0, 2)" :key="i" style="padding: 0 15px;">
                        <router-link :to="'/news/' + i.id + '?&lang=' +$i18n.locale">
                            <div class="img">
                                <v-img :lazy-src="Url+'/art_img/small_thumb/'+i.url_image"
                                    :src="Url+'/art_img/thumb_new/'+i.url_image" style="height: 100%; width: 100%; border-radius:4px;">
                                </v-img>
                            </div>
                            <div class="details">
                                <div class="cat">
                                    <!-- <p class="tl">{{i.cat_title}}</p> -->
                                    <p class="tl">{{ i.date|FormatDate }}</p>
                                </div>
                            </div>
                            <p class="tl ta">{{i.title|subStrtitle}}</p>
                            <p class="tx ta">{{i.desc|subStrtext}}</p>
                        </router-link>
                    </v-flex>
                    <v-flex xs12 sm4 md4 lg4 class="d-sm-none d-md-flex" style="padding: 0 15px;">
                        <img class="Quraans_icon" src="/assets/img/Quraans_icon.png" alt="">
                    </v-flex>
                </v-layout>
            </div>
        </div>
        <div class="Sgroup" v-if="skeleton">
            <div class="items">
                <v-layout row>
                    <v-flex xs12 sm4 md3 lg3 v-for="n in 8" :key="n" style="padding: 15px 10px;">
                        <v-skeleton-loader  width="300" min-width="300" type="card, sentences paragraph, sentences, sentences paragraph, sentences"></v-skeleton-loader><br>
                    </v-flex>
                </v-layout>
            </div>
        </div>
        <div class="Sgroup" v-if="!skeleton">
            <div class="items">
                <v-layout row>
                    <v-flex xs12 sm4 md3 lg3 v-for="i in quranResMore" :key="i" style="padding: 15px 10px;">
                        <router-link :to="'/news/' + i.id + '?&lang=' +$i18n.locale" class="item" >
                            <div class="img">
                                <v-img :lazy-src="Url+'/art_img/small_thumb/'+i.url_image"
                                    :src="Url+'/art_img/thumb_new/'+i.url_image" style="height: 100%; width: 100%; border-radius: 8px;">
                                </v-img>
                            </div>
                            <div :class="'paregs ' + $store.state.direction">
                                <p class="tl ta">{{i.title|subStrtitle3}}</p>
                                <p class="tx ta">{{i.desc|subStrtext2}}</p>
                                <div class="details dirr">
                                    <!-- <p class="tl">{{ i.date|FormatDate }}</p> -->
                                </div>
                            </div>
                        </router-link>
                    </v-flex>
                </v-layout>
                <div class="btnCon" v-if="getMore" >
                    <v-btn width="180" height="50" :loading="loading" class="ma-2 tl" style="align-self:center !important;" color="#145C5E" @click="showMore">
                        <p class="btnT tl">{{ $t('showMore') }}</p>
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
    <div class="mcon d-flex d-sm-none">
        <p class="tl">{{ title }}</p>
        <img class="Quraans_icon" src="/assets/img/Quraans_icon_mob.png" alt="">
        <div class="items">
            <router-link v-for="i in quranResMobMore" :key="i" :to="'/news/' + i.id + '?&lang=' +$i18n.locale">
                <div class="img">
                    <v-img :lazy-src="Url+'/art_img/small_thumb/'+i.url_image"
                        :src="Url+'/art_img/thumb_new/'+i.url_image" style="height: 100%; width: 100%; border-radius:4px;">
                    </v-img>
                </div>
                <div class="details">
                    <!-- <p class="tl">{{ i.date|FormatDate }}</p> -->
                </div>
                <p class="tl ta">{{i.title|subStrtitle}}</p>
                <p :class="$i18n.locale=='ar' ? 'txx' : 'tx'" class="ta">{{i.desc|subStrtext}}</p>
            </router-link>
        </div>
        <div class="btnCon" v-if="getMore">
            <v-btn width="180" height="50" :loading="loading" class="ma-2 tl" style="align-self:center !important;" color="#145C5E" @click="showMore">
                <p class="btnT tl">{{ $t('showMore') }}</p>
            </v-btn>
        </div>
    </div> 
</div>
</template>


<script>
import axios from "axios";
export default {
    name: "quran",
    data() {
        return {
            quranRes: [],
            quranResMore : [],
            quranResMobMore : [],
            skeleton : true,
            quranPage : 1,
            getMore : true,
            loader: null,
            loading: false,
            cat_id : this.$route.params.cat,
            title : '',
        }
    },
    components: {
    },
    directives: {
    },
    computed: {
    },
    watch:{
        '$route' (to,from) {
            this.cat_id= this.$route.params.cat;
            this.title = (this.cat_id == 2) ? this.$t("printedQurans") : this.$t("Manuscripts");
            document.title = this.title;
            this.quranPage = 1;
            this.getquran();
            window.scrollTo(0, 0);
        },
        loader () {
            const l = this.loader
            this[l] = !this[l]
            setTimeout(() => (this[l] = false), 2000)
            this.loader = null
        },
    },
    mounted() {
        this.title = (this.cat_id == 2) ? this.$t("printedQurans") : this.$t("Manuscripts");
    }, 
    created () {
        this.getquran();
    },
    methods:{
        getquran () {
            if (this.cat_id == '2' || this.cat_id == '3') {
                this.loader = 'loading';
                axios.get("articles/GetByCat/"+ this.cat_id +"/8?page=" + this.quranPage , {})
                .then(res => {
                    //if (res.data.data.length === 0) {this.$router.push('/?lang=' + this.$i18n.locale)}
                    //else {
                        this.quranPage ++;
                        this.quranRes = res.data.data;
                        this.quranResMore = this.quranResMore.concat(this.quranRes.slice(2,6));
                        this.quranResMobMore = this.quranResMobMore.concat(this.quranRes);
                        setTimeout(() => this.skeleton = false, 500);
                        this.showMore ();
                    //}
                })
                .catch(() => {
                });
            }
            else {this.$router.push('/?lang=' + this.$i18n.locale);}
        },
        showMore () {
            axios.get("articles/GetByCat/"+ this.cat_id +"/8?page=" + this.quranPage , {})
            .then(res => {
                this.quranPage ++;
                this.quranResMore = this.quranRes.concat(res.data.data);
                this.quranResMobMore = this.quranResMobMore.concat(res.data.data);
                if (res.data.data.length < 8) {this.getMore = false;}
            })
            .catch(() => {
            });
        },
    },
    filters: {
        subStrtitle: function (string) {if (string != null) {return string.substring(0, 95) + '...';}},
        subStrtitle3: function (string) {if (string != null) {return string.substring(0, 65) + '...';}},
        subStrtext: function (string) {if (string != null) {return string.substring(0, 190) + '...';}},
        subStrtext2: function (string) {if (string != null) {return string.substring(0, 125) + '...';}},
        FormatDate: function (unixTime) {
            let d = new Date(unixTime*1000);
            let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
            let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
            let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
            return (`${da}-${mo}-${ye}`);
        },
    },
}
</script>