<template>
<div class="news_inner dirr">
<v-layout row>
    <v-flex sm12 xs12 md8 lg8 style="padding:0 10px;" v-if="skeleton">
        <div class="mainNews">
            <br /><br />
            <v-skeleton-loader  width="800" min-width="800" type="image"></v-skeleton-loader>
            <v-skeleton-loader  width="800" min-width="800" type="card"></v-skeleton-loader>
            <v-skeleton-loader  width="800" min-width="800" type="paragraph, paragraph, paragraph, sentences, paragraph, sentences paragraph, sentences paragraph, sentences"></v-skeleton-loader>
        </div>
    </v-flex>
    <v-flex sm12 xs12 md8 lg8 style="padding:0 10px;" v-else>
        <div class="mainNews" >
            <p class="tl">{{mainNews.title}}</p>
            <div class="details">
                <div class="date">
                    <span>
                        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.8164 8.08594C13.1832 8.08594 13.4805 7.77114 13.4805 7.38281C13.4805 6.99449 13.1832 6.67969 12.8164 6.67969C12.4497 6.67969 12.1523 6.99449 12.1523 7.38281C12.1523 7.77114 12.4497 8.08594 12.8164 8.08594Z" fill="#6C6C6C" fill-opacity="0.6"/>
                        <path d="M14.3438 1.40625H13.4805V0.703125C13.4805 0.314789 13.1832 0 12.8164 0C12.4496 0 12.1523 0.314789 12.1523 0.703125V1.40625H9.13086V0.703125C9.13086 0.314789 8.83356 0 8.4668 0C8.10003 0 7.80273 0.314789 7.80273 0.703125V1.40625H4.81445V0.703125C4.81445 0.314789 4.51715 0 4.15039 0C3.78363 0 3.48633 0.314789 3.48633 0.703125V1.40625H2.65625C1.19159 1.40625 0 2.66794 0 4.21875V15.1875C0 16.7383 1.19159 18 2.65625 18H7.73633C8.10309 18 8.40039 17.6852 8.40039 17.2969C8.40039 16.9085 8.10309 16.5938 7.73633 16.5938H2.65625C1.92392 16.5938 1.32812 15.9629 1.32812 15.1875V4.21875C1.32812 3.44334 1.92392 2.8125 2.65625 2.8125H3.48633V3.51562C3.48633 3.90396 3.78363 4.21875 4.15039 4.21875C4.51715 4.21875 4.81445 3.90396 4.81445 3.51562V2.8125H7.80273V3.51562C7.80273 3.90396 8.10003 4.21875 8.4668 4.21875C8.83356 4.21875 9.13086 3.90396 9.13086 3.51562V2.8125H12.1523V3.51562C12.1523 3.90396 12.4496 4.21875 12.8164 4.21875C13.1832 4.21875 13.4805 3.90396 13.4805 3.51562V2.8125H14.3438C15.0761 2.8125 15.6719 3.44334 15.6719 4.21875V8.22656C15.6719 8.6149 15.9692 8.92969 16.3359 8.92969C16.7027 8.92969 17 8.6149 17 8.22656V4.21875C17 2.66794 15.8084 1.40625 14.3438 1.40625Z" fill="#6C6C6C" fill-opacity="0.6"/>
                        <path d="M12.9824 9.49219C10.7671 9.49219 8.96484 11.4005 8.96484 13.7461C8.96484 16.0917 10.7671 18 12.9824 18C15.1977 18 17 16.0917 17 13.7461C17 11.4005 15.1977 9.49219 12.9824 9.49219ZM12.9824 16.5938C11.4995 16.5938 10.293 15.3163 10.293 13.7461C10.293 12.1759 11.4995 10.8984 12.9824 10.8984C14.4654 10.8984 15.6719 12.1759 15.6719 13.7461C15.6719 15.3163 14.4654 16.5938 12.9824 16.5938Z" fill="#098486"/>
                        <path d="M13.9453 13.043H13.6465V12.3047C13.6465 11.9164 13.3492 11.6016 12.9824 11.6016C12.6157 11.6016 12.3184 11.9164 12.3184 12.3047V13.7461C12.3184 14.1344 12.6157 14.4492 12.9824 14.4492H13.9453C14.3121 14.4492 14.6094 14.1344 14.6094 13.7461C14.6094 13.3578 14.3121 13.043 13.9453 13.043Z" fill="#6C6C6C" fill-opacity="0.6"/>
                        <path d="M9.92773 8.08594C10.2945 8.08594 10.5918 7.77114 10.5918 7.38281C10.5918 6.99449 10.2945 6.67969 9.92773 6.67969C9.56098 6.67969 9.26367 6.99449 9.26367 7.38281C9.26367 7.77114 9.56098 8.08594 9.92773 8.08594Z" fill="#6C6C6C" fill-opacity="0.6"/>
                        <path d="M7.03906 11.1445C7.40581 11.1445 7.70312 10.8297 7.70312 10.4414C7.70312 10.0531 7.40581 9.73828 7.03906 9.73828C6.67231 9.73828 6.375 10.0531 6.375 10.4414C6.375 10.8297 6.67231 11.1445 7.03906 11.1445Z" fill="#6C6C6C" fill-opacity="0.6"/>
                        <path d="M4.15039 8.08594C4.51714 8.08594 4.81445 7.77114 4.81445 7.38281C4.81445 6.99449 4.51714 6.67969 4.15039 6.67969C3.78364 6.67969 3.48633 6.99449 3.48633 7.38281C3.48633 7.77114 3.78364 8.08594 4.15039 8.08594Z" fill="#098486"/>
                        <path d="M4.15039 11.1445C4.51714 11.1445 4.81445 10.8297 4.81445 10.4414C4.81445 10.0531 4.51714 9.73828 4.15039 9.73828C3.78364 9.73828 3.48633 10.0531 3.48633 10.4414C3.48633 10.8297 3.78364 11.1445 4.15039 11.1445Z" fill="#6C6C6C" fill-opacity="0.6"/>
                        <path d="M4.15039 14.2031C4.51714 14.2031 4.81445 13.8883 4.81445 13.5C4.81445 13.1117 4.51714 12.7969 4.15039 12.7969C3.78364 12.7969 3.48633 13.1117 3.48633 13.5C3.48633 13.8883 3.78364 14.2031 4.15039 14.2031Z" fill="#6C6C6C" fill-opacity="0.6"/>
                        <path d="M7.03906 14.2031C7.40581 14.2031 7.70312 13.8883 7.70312 13.5C7.70312 13.1117 7.40581 12.7969 7.03906 12.7969C6.67231 12.7969 6.375 13.1117 6.375 13.5C6.375 13.8883 6.67231 14.2031 7.03906 14.2031Z" fill="#6C6C6C" fill-opacity="0.6"/>
                        <path d="M7.03906 8.08594C7.40581 8.08594 7.70312 7.77114 7.70312 7.38281C7.70312 6.99449 7.40581 6.67969 7.03906 6.67969C6.67231 6.67969 6.375 6.99449 6.375 7.38281C6.375 7.77114 6.67231 8.08594 7.03906 8.08594Z" fill="#6C6C6C" fill-opacity="0.6"/>
                        </svg>
                    </span>
                    <p class="tl">{{mainNews.date|FormatDate }}</p>
                </div>
            </div>
            <div class="media">
                <div class="img" v-if="!mainNews.video">
                    <v-img :lazy-src="Url+'/art_img/small_thumb/'+mainNews.url_image"
                        :src="Url+'/art_img/art_img_main/'+mainNews.url_image" style="border-radius: 10px; width: 100%; height: 100%;">
                    </v-img>
                    <!-- <a data-fancybox="gallery" :data-caption="i.title1" :href="'https://alkafeel.net/news/images/main/' + i.image" class="">
                        <img :src="'https://alkafeel.net/news/mcroped96/600/'+i.image" />
                    </a> -->
                </div>
            </div>
            <div class="social">
                <a :href="'https://www.facebook.com/sharer/sharer.php?u=https://alkafeel.net/news/?id='+mainNews.id" target="_blank" class="facebook">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M27 0H5C2.23858 0 0 2.23858 0 5V27C0 29.7614 2.23858 32 5 32H27C29.7614 32 32 29.7614 32 27V5C32 2.23858 29.7614 0 27 0Z" fill="#1877F2"/>
                    <path d="M24 16C24 11.6 20.4 8 16 8C11.6 8 8 11.6 8 16C8 20 10.9 23.3 14.7 23.9V18.3H12.7V16H14.7V14.2C14.7 12.2 15.9 11.1 17.7 11.1C18.6 11.1 19.5 11.3 19.5 11.3V13.3H18.5C17.5 13.3 17.2 13.9 17.2 14.5V16H19.4L19 18.3H17.1V24C21.1 23.4 24 20 24 16Z" fill="white"/>
                    </svg>
                </a>
                <a :href="'https://twitter.com/intent/tweet?text=https://alkafeel.net/news/?id=15058'+mainNews.id" target="_blank" class="twitter">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M27 0H5C2.23858 0 0 2.23858 0 5V27C0 29.7614 2.23858 32 5 32H27C29.7614 32 32 29.7614 32 27V5C32 2.23858 29.7614 0 27 0Z" fill="#1DA1F2"/>
                    <path d="M24 11C23.4 11.3 22.8 11.4 22.1 11.5C22.8 11.1 23.3 10.5 23.5 9.7C22.9 10.1 22.2 10.3 21.4 10.5C20.8 9.9 19.9 9.5 19 9.5C16.9 9.5 15.3 11.5 15.8 13.5C13.1 13.4 10.7 12.1 9 10.1C8.1 11.6 8.6 13.5 10 14.5C9.5 14.5 9 14.3 8.5 14.1C8.5 15.6 9.6 17 11.1 17.4C10.6 17.5 10.1 17.6 9.6 17.5C10 18.8 11.2 19.8 12.7 19.8C11.5 20.7 9.7 21.2 8 21C9.5 21.9 11.2 22.5 13 22.5C19.1 22.5 22.5 17.4 22.3 12.7C23 12.3 23.6 11.7 24 11Z" fill="white"/>
                    </svg>
                </a>
                <a :href="'https://telegram.me/share/?url=https://alkafeel.net/news/?id='+mainNews.id" target="_blank" class="telegram">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M27 0H5C2.23858 0 0 2.23858 0 5V27C0 29.7614 2.23858 32 5 32H27C29.7614 32 32 29.7614 32 27V5C32 2.23858 29.7614 0 27 0Z" fill="#0088CC"/>
                    <path d="M8.09992 15.7083C12.3949 13.8371 15.2589 12.6034 16.6919 12.0074C20.7834 10.3056 21.6335 10.01 22.1877 10.0001C22.3095 9.99805 22.582 10.0283 22.7586 10.1715C22.9076 10.2924 22.9486 10.4558 22.9683 10.5705C22.9879 10.6851 23.0123 10.9464 22.9929 11.1505C22.7712 13.4801 21.8118 19.1335 21.3237 21.7427C21.1172 22.8468 20.7105 23.217 20.3168 23.2532C19.4613 23.3319 18.8116 22.6878 17.9829 22.1446C16.6862 21.2946 15.9537 20.7654 14.695 19.936C13.2404 18.9774 14.1834 18.4506 15.0124 17.5896C15.2293 17.3643 18.999 13.9355 19.0719 13.6244C19.0811 13.5855 19.0895 13.4405 19.0034 13.3639C18.9172 13.2874 18.7901 13.3136 18.6983 13.3344C18.5683 13.3639 16.4968 14.7331 12.4839 17.4419C11.8959 17.8457 11.3633 18.0424 10.8862 18.0321C10.3601 18.0207 9.34822 17.7346 8.59598 17.4901C7.67333 17.1902 6.94002 17.0316 7.00388 16.5223C7.03714 16.257 7.40248 15.9856 8.09992 15.7083Z" fill="white"/>
                    </svg>
                </a>
            </div>
            <p :class="$i18n.locale=='ar' ? 'txx' : 'tx'" v-html="mainNews.desc"></p>
            <div class="album">
                <v-row >
                    <v-col v-for="n in mainNews.images" :key="n" class="d-flex child-flex" cols="4">
                        <a data-fancybox="gallery" :data-caption="mainNews.title" :href="Url+'/art_img/art_img_main/'+n.image_name" class="">
                            <img :src="Url+'/art_img/art_img_main/'+n.image_name" />
                        </a>
                    </v-col>
                </v-row>
            </div>
        </div>
    </v-flex>
    <v-flex sm12 xs12 md4 lg4 style="padding:0 10px;" v-if="skeleton">
        <div class="sidebar">
            <br /><br /><br />
            <v-skeleton-loader  width="300" min-width="300" type="card"></v-skeleton-loader>
            <v-skeleton-loader  width="300" min-width="300" type="card"></v-skeleton-loader>
            <v-skeleton-loader  width="300" min-width="300" type="card"></v-skeleton-loader>
            <v-skeleton-loader  width="300" min-width="300" type="card"></v-skeleton-loader>
        </div>
    </v-flex>
    <v-flex sm12 xs12 md4 lg4 style="padding:0 10px;" v-else>
        <div class="sidebar">
            <div class="related">
                <p class="Title tl">{{ $t("last_news") }} </p>
                <div class="items">
                    <v-layout row>
                        <v-flex xs12 sm6 md12 lg12 v-for="i in relatedNews" :key="i" style="">
                            <router-link :to="'/news/' + i.id + '?&lang=' +$i18n.locale" class="Oitem">
                                <div class="img">
                                    <v-img :lazy-src="Url+'/art_img/small_thumb/'+i.url_image"
                                        :src="Url+'/art_img/thumb_new/'+i.url_image" style="height: 100%; width: 100%; border-radius: 4px;">
                                    </v-img>
                                </div>
                                <div :class="'paregs ' + $store.state.direction">
                                    <p class="tl ta">{{i.title|subStrtitle}}</p>
                                    <div class="details">
                                        <div class="date">
                                            <span>
                                                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.8164 8.08594C13.1832 8.08594 13.4805 7.77114 13.4805 7.38281C13.4805 6.99449 13.1832 6.67969 12.8164 6.67969C12.4497 6.67969 12.1523 6.99449 12.1523 7.38281C12.1523 7.77114 12.4497 8.08594 12.8164 8.08594Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                                <path d="M14.3438 1.40625H13.4805V0.703125C13.4805 0.314789 13.1832 0 12.8164 0C12.4496 0 12.1523 0.314789 12.1523 0.703125V1.40625H9.13086V0.703125C9.13086 0.314789 8.83356 0 8.4668 0C8.10003 0 7.80273 0.314789 7.80273 0.703125V1.40625H4.81445V0.703125C4.81445 0.314789 4.51715 0 4.15039 0C3.78363 0 3.48633 0.314789 3.48633 0.703125V1.40625H2.65625C1.19159 1.40625 0 2.66794 0 4.21875V15.1875C0 16.7383 1.19159 18 2.65625 18H7.73633C8.10309 18 8.40039 17.6852 8.40039 17.2969C8.40039 16.9085 8.10309 16.5938 7.73633 16.5938H2.65625C1.92392 16.5938 1.32812 15.9629 1.32812 15.1875V4.21875C1.32812 3.44334 1.92392 2.8125 2.65625 2.8125H3.48633V3.51562C3.48633 3.90396 3.78363 4.21875 4.15039 4.21875C4.51715 4.21875 4.81445 3.90396 4.81445 3.51562V2.8125H7.80273V3.51562C7.80273 3.90396 8.10003 4.21875 8.4668 4.21875C8.83356 4.21875 9.13086 3.90396 9.13086 3.51562V2.8125H12.1523V3.51562C12.1523 3.90396 12.4496 4.21875 12.8164 4.21875C13.1832 4.21875 13.4805 3.90396 13.4805 3.51562V2.8125H14.3438C15.0761 2.8125 15.6719 3.44334 15.6719 4.21875V8.22656C15.6719 8.6149 15.9692 8.92969 16.3359 8.92969C16.7027 8.92969 17 8.6149 17 8.22656V4.21875C17 2.66794 15.8084 1.40625 14.3438 1.40625Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                                <path d="M12.9824 9.49219C10.7671 9.49219 8.96484 11.4005 8.96484 13.7461C8.96484 16.0917 10.7671 18 12.9824 18C15.1977 18 17 16.0917 17 13.7461C17 11.4005 15.1977 9.49219 12.9824 9.49219ZM12.9824 16.5938C11.4995 16.5938 10.293 15.3163 10.293 13.7461C10.293 12.1759 11.4995 10.8984 12.9824 10.8984C14.4654 10.8984 15.6719 12.1759 15.6719 13.7461C15.6719 15.3163 14.4654 16.5938 12.9824 16.5938Z" fill="#098486"/>
                                                <path d="M13.9453 13.043H13.6465V12.3047C13.6465 11.9164 13.3492 11.6016 12.9824 11.6016C12.6157 11.6016 12.3184 11.9164 12.3184 12.3047V13.7461C12.3184 14.1344 12.6157 14.4492 12.9824 14.4492H13.9453C14.3121 14.4492 14.6094 14.1344 14.6094 13.7461C14.6094 13.3578 14.3121 13.043 13.9453 13.043Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                                <path d="M9.92773 8.08594C10.2945 8.08594 10.5918 7.77114 10.5918 7.38281C10.5918 6.99449 10.2945 6.67969 9.92773 6.67969C9.56098 6.67969 9.26367 6.99449 9.26367 7.38281C9.26367 7.77114 9.56098 8.08594 9.92773 8.08594Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                                <path d="M7.03906 11.1445C7.40581 11.1445 7.70312 10.8297 7.70312 10.4414C7.70312 10.0531 7.40581 9.73828 7.03906 9.73828C6.67231 9.73828 6.375 10.0531 6.375 10.4414C6.375 10.8297 6.67231 11.1445 7.03906 11.1445Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                                <path d="M4.15039 8.08594C4.51714 8.08594 4.81445 7.77114 4.81445 7.38281C4.81445 6.99449 4.51714 6.67969 4.15039 6.67969C3.78364 6.67969 3.48633 6.99449 3.48633 7.38281C3.48633 7.77114 3.78364 8.08594 4.15039 8.08594Z" fill="#098486"/>
                                                <path d="M4.15039 11.1445C4.51714 11.1445 4.81445 10.8297 4.81445 10.4414C4.81445 10.0531 4.51714 9.73828 4.15039 9.73828C3.78364 9.73828 3.48633 10.0531 3.48633 10.4414C3.48633 10.8297 3.78364 11.1445 4.15039 11.1445Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                                <path d="M4.15039 14.2031C4.51714 14.2031 4.81445 13.8883 4.81445 13.5C4.81445 13.1117 4.51714 12.7969 4.15039 12.7969C3.78364 12.7969 3.48633 13.1117 3.48633 13.5C3.48633 13.8883 3.78364 14.2031 4.15039 14.2031Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                                <path d="M7.03906 14.2031C7.40581 14.2031 7.70312 13.8883 7.70312 13.5C7.70312 13.1117 7.40581 12.7969 7.03906 12.7969C6.67231 12.7969 6.375 13.1117 6.375 13.5C6.375 13.8883 6.67231 14.2031 7.03906 14.2031Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                                <path d="M7.03906 8.08594C7.40581 8.08594 7.70312 7.77114 7.70312 7.38281C7.70312 6.99449 7.40581 6.67969 7.03906 6.67969C6.67231 6.67969 6.375 6.99449 6.375 7.38281C6.375 7.77114 6.67231 8.08594 7.03906 8.08594Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                                </svg>
                                            </span>
                                            <p class="tl">{{ i.date|FormatDate }}</p>
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </v-flex>
                    </v-layout>
                </div>
            </div>
            <!-- <div class="most">
                <p class="Title tl">{{ $t("mostviewed") }}</p>
                <div class="items">
                    <v-layout row>
                        <v-flex xs12 sm6 md12 lg12 v-for="i in mostNews" :key="i" style="">
                            <router-link :to="'/news/' + i.id + '?&lang=' +$i18n.locale" class="Oitem">
                                <div class="img">
                                    <v-img :lazy-src="i.url_image"
                                        :src="i.url_image" style="height: 100%; width: 100%; border-radius: 4px;">
                                    </v-img>
                                </div>
                                <div :class="'paregs ' + $store.state.direction">
                                    <p class="tl ta">{{i.title|subStrtitle}}</p>
                                    <div class="details">
                                        <div class="date">
                                            <span>
                                                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.8164 8.08594C13.1832 8.08594 13.4805 7.77114 13.4805 7.38281C13.4805 6.99449 13.1832 6.67969 12.8164 6.67969C12.4497 6.67969 12.1523 6.99449 12.1523 7.38281C12.1523 7.77114 12.4497 8.08594 12.8164 8.08594Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                                <path d="M14.3438 1.40625H13.4805V0.703125C13.4805 0.314789 13.1832 0 12.8164 0C12.4496 0 12.1523 0.314789 12.1523 0.703125V1.40625H9.13086V0.703125C9.13086 0.314789 8.83356 0 8.4668 0C8.10003 0 7.80273 0.314789 7.80273 0.703125V1.40625H4.81445V0.703125C4.81445 0.314789 4.51715 0 4.15039 0C3.78363 0 3.48633 0.314789 3.48633 0.703125V1.40625H2.65625C1.19159 1.40625 0 2.66794 0 4.21875V15.1875C0 16.7383 1.19159 18 2.65625 18H7.73633C8.10309 18 8.40039 17.6852 8.40039 17.2969C8.40039 16.9085 8.10309 16.5938 7.73633 16.5938H2.65625C1.92392 16.5938 1.32812 15.9629 1.32812 15.1875V4.21875C1.32812 3.44334 1.92392 2.8125 2.65625 2.8125H3.48633V3.51562C3.48633 3.90396 3.78363 4.21875 4.15039 4.21875C4.51715 4.21875 4.81445 3.90396 4.81445 3.51562V2.8125H7.80273V3.51562C7.80273 3.90396 8.10003 4.21875 8.4668 4.21875C8.83356 4.21875 9.13086 3.90396 9.13086 3.51562V2.8125H12.1523V3.51562C12.1523 3.90396 12.4496 4.21875 12.8164 4.21875C13.1832 4.21875 13.4805 3.90396 13.4805 3.51562V2.8125H14.3438C15.0761 2.8125 15.6719 3.44334 15.6719 4.21875V8.22656C15.6719 8.6149 15.9692 8.92969 16.3359 8.92969C16.7027 8.92969 17 8.6149 17 8.22656V4.21875C17 2.66794 15.8084 1.40625 14.3438 1.40625Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                                <path d="M12.9824 9.49219C10.7671 9.49219 8.96484 11.4005 8.96484 13.7461C8.96484 16.0917 10.7671 18 12.9824 18C15.1977 18 17 16.0917 17 13.7461C17 11.4005 15.1977 9.49219 12.9824 9.49219ZM12.9824 16.5938C11.4995 16.5938 10.293 15.3163 10.293 13.7461C10.293 12.1759 11.4995 10.8984 12.9824 10.8984C14.4654 10.8984 15.6719 12.1759 15.6719 13.7461C15.6719 15.3163 14.4654 16.5938 12.9824 16.5938Z" fill="#098486"/>
                                                <path d="M13.9453 13.043H13.6465V12.3047C13.6465 11.9164 13.3492 11.6016 12.9824 11.6016C12.6157 11.6016 12.3184 11.9164 12.3184 12.3047V13.7461C12.3184 14.1344 12.6157 14.4492 12.9824 14.4492H13.9453C14.3121 14.4492 14.6094 14.1344 14.6094 13.7461C14.6094 13.3578 14.3121 13.043 13.9453 13.043Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                                <path d="M9.92773 8.08594C10.2945 8.08594 10.5918 7.77114 10.5918 7.38281C10.5918 6.99449 10.2945 6.67969 9.92773 6.67969C9.56098 6.67969 9.26367 6.99449 9.26367 7.38281C9.26367 7.77114 9.56098 8.08594 9.92773 8.08594Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                                <path d="M7.03906 11.1445C7.40581 11.1445 7.70312 10.8297 7.70312 10.4414C7.70312 10.0531 7.40581 9.73828 7.03906 9.73828C6.67231 9.73828 6.375 10.0531 6.375 10.4414C6.375 10.8297 6.67231 11.1445 7.03906 11.1445Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                                <path d="M4.15039 8.08594C4.51714 8.08594 4.81445 7.77114 4.81445 7.38281C4.81445 6.99449 4.51714 6.67969 4.15039 6.67969C3.78364 6.67969 3.48633 6.99449 3.48633 7.38281C3.48633 7.77114 3.78364 8.08594 4.15039 8.08594Z" fill="#098486"/>
                                                <path d="M4.15039 11.1445C4.51714 11.1445 4.81445 10.8297 4.81445 10.4414C4.81445 10.0531 4.51714 9.73828 4.15039 9.73828C3.78364 9.73828 3.48633 10.0531 3.48633 10.4414C3.48633 10.8297 3.78364 11.1445 4.15039 11.1445Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                                <path d="M4.15039 14.2031C4.51714 14.2031 4.81445 13.8883 4.81445 13.5C4.81445 13.1117 4.51714 12.7969 4.15039 12.7969C3.78364 12.7969 3.48633 13.1117 3.48633 13.5C3.48633 13.8883 3.78364 14.2031 4.15039 14.2031Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                                <path d="M7.03906 14.2031C7.40581 14.2031 7.70312 13.8883 7.70312 13.5C7.70312 13.1117 7.40581 12.7969 7.03906 12.7969C6.67231 12.7969 6.375 13.1117 6.375 13.5C6.375 13.8883 6.67231 14.2031 7.03906 14.2031Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                                <path d="M7.03906 8.08594C7.40581 8.08594 7.70312 7.77114 7.70312 7.38281C7.70312 6.99449 7.40581 6.67969 7.03906 6.67969C6.67231 6.67969 6.375 6.99449 6.375 7.38281C6.375 7.77114 6.67231 8.08594 7.03906 8.08594Z" fill="#6C6C6C" fill-opacity="0.6"/>
                                                </svg>
                                            </span>
                                            <p class="tl">{{ i.date|FormatDate }}</p>
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </v-flex>
                    </v-layout>
                </div>
            </div> -->
        </div>
    </v-flex>
</v-layout>
</div>
</template>


<script>
import axios from "axios";
import { Fancybox} from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
export default {
    name: "news",
    data() {
        return {
            mainNews: [],
            title : '',
            skeleton : true,
            relatedNews : [],
            //mostNews : [],
            news_id : this.$route.params.id,
        }
    },
    beforeRouteEnter(to, from, next) {
        axios.get("articles/" + to.params.id, {})
        .then(res => {
            next(vm => {
                if (res.data.data.length == 0) {vm.$router.push('/?lang=' + vm.$i18n.locale)}
                else {
                    vm.mainNews = res.data.data;
                    vm.skeleton=false;
                    vm.getrelated();
                }
            })
        })
        .catch(() => {
        });
    },
    components: {
    },
    directives: {
    },
    computed: {
    },
    watch: {
        '$route' (to,from) {
            this.news_id =  this.$route.params.id;
            window.scrollTo(0, 0);
            this.getmainNews();
        },
    },
    mounted() {
        document.title =this.$t("news");
        this.iniFancy();
    }, 
    created () {
        //this.getrelated();
    },
    methods:{
        getmainNews(){
              axios.get("articles/" + this.news_id, {
            })
            .then(res => {
                if (res.data.data.length == 0) {this.$router.push('/?lang=' + this.$i18n.locale)}
                else {
                    this.mainNews = res.data.data;
                    this.skeleton=false;
                }
                this.getrelated();
            })
            .catch(() => {
            });
        },
        getrelated () {
            axios.get("articles/GetByCat/1/8?page=1" , {
            })
            .then(res => {
                this.relatedNews = res.data.data;
            })
            .catch(() => {
            });
        },
        iniFancy () {
            Fancybox.bind('[data-fancybox="gallery"]', {
                dragToClose: false,
                Toolbar: false,
                closeButton: "top",
                Image: {
                zoom: false,
                },
                on: {
                    initCarousel: (fancybox) => {
                    const slide = fancybox.Carousel.slides[fancybox.Carousel.page];
                    fancybox.$container.style.setProperty("--bg-image",`url("${slide.$thumb.src}")`);
                    },
                    "Carousel.change": (fancybox, carousel, to, from) => {
                    const slide = carousel.slides[to];
                    fancybox.$container.style.setProperty("--bg-image", `url("${slide.$thumb.src}")`);
                    },
                },
            });
        },
    },
    filters: {
        subStrtitle: function (string) {if (string != null) {return string.substring(0, 60) + '...';}},
        subStrimpoNewsTitle: function (string) {if (string != null) {return string.substring(0, 80) + '...';}},
        FormatDate: function (unixTime) {
            let d = new Date(unixTime*1000);
            let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
            let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
            let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
            return (`${da}-${mo}-${ye}`);
        },
    },
    
}
</script>