<template>
<div class="library">
    <div class="title"><p class="tl">{{ $t("Elibrary") }}</p></div>
    <v-container class="library_cont">
        

            <div class="tabs">
                <div class="slider">
                    <div :class="'sbtn lbtn2 d-none '+ $store.state.direction"></div>
                    <swiper class="swiper dirr" ref="mySwiper" :options="swiperOptions2">
                        <swiper-slide v-for="(i,n) in libraryRes" :key="i">
                            <v-btn mx-10 class="btn" :input-value="n" :class="active==n ? 'greeen white--text' : ''" @click="showlibrary(i.category_id, n)">
                                
                                <div class="serv2" style="padding-top:12px"><svg viewBox="0 0 27 20"><use xlink:href="/assets/img/books/book.svg#view"></use></svg></div>
                                {{i.title}}
                            </v-btn>
                        </swiper-slide>
                    </swiper>
                    <div :class="'sbtn rbtn2 d-none '+ $store.state.direction"></div>
                </div>
            </div>
            

            <v-layout row pt-3 px-3 mt-3 >

                <v-flex >
                    <v-layout row px-1 py-2 class="centered">
                        <template v-if="catLoad">
                            <v-skeleton-loader width="195" height="296" style="margin: 5px;" v-bind="attrs" type="card"></v-skeleton-loader>
                            <v-skeleton-loader width="195" height="296" style="margin: 5px;" v-bind="attrs" type="card"></v-skeleton-loader>
                            <v-skeleton-loader width="195" height="296" style="margin: 5px;" v-bind="attrs" type="card"></v-skeleton-loader>
                            <v-skeleton-loader width="195" height="296" style="margin: 5px;" v-bind="attrs" type="card"></v-skeleton-loader>
                            <v-skeleton-loader width="195" height="296" style="margin: 5px;" v-bind="attrs" type="card"></v-skeleton-loader>
                            <v-skeleton-loader width="195" height="296" style="margin: 5px;" v-bind="attrs" type="card"></v-skeleton-loader>
                            <v-skeleton-loader width="195" height="296" style="margin: 5px;" v-bind="attrs" type="card"></v-skeleton-loader>
                            <v-skeleton-loader width="195" height="296" style="margin: 5px;" v-bind="attrs" type="card"></v-skeleton-loader>
                            <v-skeleton-loader width="195" height="296" style="margin: 5px;" v-bind="attrs" type="card"></v-skeleton-loader>
                            <v-skeleton-loader width="195" height="296" style="margin: 5px;" v-bind="attrs" type="card"></v-skeleton-loader>
                            <v-skeleton-loader width="195" height="296" style="margin: 5px;" v-bind="attrs" type="card"></v-skeleton-loader>
                            <v-skeleton-loader width="195" height="296" style="margin: 5px;" v-bind="attrs" type="card"></v-skeleton-loader>
                        </template>
                        <template v-else >
                            <template v-for="i in libraryitemsRes">
                                <v-card flat :to="'/library/'+i.id+'?lang='+$i18n.locale" height="296" class="latest_items2" width="185" :key="i">
                                 <v-img height="100%" :lazy-src="Url+'/imageBooks/small_thumb/'+i.image_url"
                                        :src="Url+'/imageBooks/thumb/'+i.image_url" style="">
                                    </v-img>

                                    <!-- <v-img height="232" :src="'https://alkafeel.net/publications/img/200/'+i.image_url"></v-img> -->
                                    <v-card-subtitle class="sub" v-if="i.title != ''"  :title="i.title"><p class="t1">{{i.title.substring(0,30)+".."}}</p></v-card-subtitle>
                                </v-card>
                            </template>
                        </template>
                    </v-layout>
                </v-flex>

            </v-layout>


            <template v-if="libraryitemsRes != ''">
                <v-layout row class="centered mb-3">
                    <div v-if="getMore_btn" >
                        <v-btn width="180" height="50" style="justify-content: center;" :loading="btnLoader" class="ma-2 tl" color="#145C5E" @click="getMore">
                            <p class="btnT white--text mb-0 tl">{{ $t('showMore') }}</p>
                        </v-btn>
                    </div>
                </v-layout>
            </template>






            <template v-if="pageCont">
                <p class="tl" style="font-size:22px">{{$t('latest_books')}}</p>

                <v-layout row pt-3 class="hidden-md-and-down d_align">
                    <v-flex lg4 xl4 sm12>
                        <template v-if="latestLoad">
                            <v-skeleton-loader width="100%" min-height="620px" style="margin: 0 5px;" v-bind="attrs" type="image"></v-skeleton-loader>
                        </template>
                        <template v-else>
                            <v-card flat :to="'/library/'+items[0].id+'?lang='+$i18n.locale" light class="main_card">
                            
                                <v-img class="main_img" :lazy-src="Url+'/imageBooks/small_thumb/'+items[0].image_url"
                                :src="Url+'/imageBooks/thumb_main/'+items[0].image_url"></v-img>
                                <v-card-subtitle v-if="items[0].title != ''"  :title="items[0].title">{{items[0].title.substring(0,230)+".."}}</v-card-subtitle>
                            </v-card>
                        </template>
                        
                    </v-flex>
                    <v-flex lg8 xl8 sm12>
                        <v-layout row px-1 py-2 >
                            <template v-if="latestLoad">
                                <v-skeleton-loader width="195" height="296" style="margin: 5px;" v-bind="attrs" type="card"></v-skeleton-loader>
                                <v-skeleton-loader width="195" height="296" style="margin: 5px;" v-bind="attrs" type="card"></v-skeleton-loader>
                                <v-skeleton-loader width="195" height="296" style="margin: 5px;" v-bind="attrs" type="card"></v-skeleton-loader>
                                <v-skeleton-loader width="195" height="296" style="margin: 5px;" v-bind="attrs" type="card"></v-skeleton-loader>
                                <v-skeleton-loader width="195" height="296" style="margin: 5px;" v-bind="attrs" type="card"></v-skeleton-loader>
                                <v-skeleton-loader width="195" height="296" style="margin: 5px;" v-bind="attrs" type="card"></v-skeleton-loader>
                                <v-skeleton-loader width="195" height="296" style="margin: 5px;" v-bind="attrs" type="card"></v-skeleton-loader>
                                <v-skeleton-loader width="195" height="296" style="margin: 5px;" v-bind="attrs" type="card"></v-skeleton-loader>
                            </template>
                            <template v-else v-for="item in items.slice(1, 9)">
                                
                                    <v-card flat :to="'/library/'+item.id+'?lang='+$i18n.locale" height="296" class="latest_items" width="195" :key="item.id">
                                        <v-img height="232" :lazy-src="Url+'/imageBooks/small_thumb/'+item.image_url"
                                                        :src="Url+'/imageBooks/thumb/'+item.image_url"></v-img>
                                        <v-card-subtitle class="sub" v-if="item.title != ''"  :title="item.title"><p class="t1">{{item.title.substring(0,30)+".."}}</p></v-card-subtitle>
                                    </v-card>
                                    
                            </template>
                        </v-layout>

                    </v-flex>
                </v-layout>


                <v-layout row pt-3 px-3 class="hidden-lg-and-up">

                    <v-flex>

                        
                        <v-layout row px-1 py-2 class="centered">
                            <template v-for="item in items.slice(0, 10)">
                                
                                    <v-card flat :to="'/library/'+item.id+'?lang='+$i18n.locale" height="296" class="latest_items" width="185" :key="item">
                                        <v-img height="232" :lazy-src="Url+'/imageBooks/small_thumb/'+item.image_url"
                                                        :src="Url+'/imageBooks/thumb/'+item.image_url"></v-img>
                                        <v-card-subtitle class="sub" v-if="item.title != ''"  :title="item.title"><p class="t1">{{item.title.substring(0,30)+".."}}</p></v-card-subtitle>
                                    </v-card>
                                    
                            </template>
                        </v-layout>
                    </v-flex>

                </v-layout>


                <template v-for="(i, c) in libraryRes.slice(0,2)" >
                    <p class="tl" mt-3 style="font-size:22px; margin-top:25px" :key="i">{{i.title}}</p>

                    <v-layout row pt-3 px-3 :key="i">

                        <v-flex >
                            <v-layout row px-1 py-2 class="centered">
                                

                                    <template v-for="items in library_ini_items[c]">
                                        <template v-for="item in items">
                                            <v-card flat :to="'/library/'+item.id+'?lang='+$i18n.locale" height="296" class="latest_items2" width="185" :key="item">
                                                <v-img height="232" :src="'https://alkafeel.net/publications/img/200/'+item.image_url"></v-img>
                                                <v-card-subtitle class="sub" v-if="item.title"  :title="item.title"><p class="t1">{{item.title.substring(0,30)+".."}}</p></v-card-subtitle>
                                            </v-card>
                                        </template>
                                            
                                            
                                    </template>

                            </v-layout>
                        </v-flex>

                    </v-layout>
                </template>
            </template>
            




    </v-container>


    
    
</div>
</template>


<script>
import Axios from "axios";
import { Swiper, SwiperSlide, directive, Pagination, } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
export default {
    data() {
        return {
            libraryRes: [],
            libraryitemsRes: [],
            library_ini_items: [],
            catId: 0,
            getMore_btn: true,
            items: [],
            swiperOptions2: {
                slidesPerView: 'auto',
                spaceBetween: 25,
                keyboard: true,
                navigation: {
                    nextEl: ".rbtn2",
                    prevEl: ".lbtn2",
                },
            },
            active: null,
            attrs: {
                class: 'mb-6',
                boilerplate: false,
                elevation: 2,
            },
            latestLoad: true,
            catLoad: false,
            bookPage: 1,
            btnLoader: false,
            pageCont: true
        }
    },
    components: {
        Swiper, SwiperSlide, Pagination,
    },
    directives: {
        swiper: directive,
    },
    computed: {
        swiper() {return this.$refs.mySwiper.$swiper},
    },
    watch: {
        libraryRes: function (t1) {
            // console.log(t1);
            for (let i = 0; i < 2; i++) {
                this.library_ini (t1[i].id);
            }
        }
    },
    mounted() {
        document.title =this.$t("Elibrary");
    }, 
    created () {
        // console.log(this.libraryRes[0].id);
        // this.library_ini ();
    },
    beforeCreate () { // testing

        Axios.get("category_books")
            .then(d => 
            {
                this.libraryRes = d.data.data;
            });

        Axios
        .get('books')
        .then(d => {
            this.latestLoad = false;
            this.items = d.data.data;
        });


        // Axios
        // .get('https://alkafeel.net/main_bg/data/Holy_quran_library_test.json')
        // .then(d => {
        //     this.latestLoad = false;
        //     // this.libraryRes  = d.data['sections'];
        //     this.items = d.data['items'];
        // });
        
    },
    methods:{
        getLibrary() {
            Axios.get("https://alkafeel.net/main_bg/data/Holy_quran_library_test.json")
            .then(d => 
            {
                this.latestLoad = false;
                this.libraryRes  = d.data['sections'];
                // this.items = d.data['items'];
            });
            // this.showlibrary (1, 1);
        },
        showlibrary (x, n) {
            this.active = n;
            this.pageCont = false;
            this.catLoad = true;
            this.catId = x,
            Axios.get("books/getByCat/"+x+"/12")
            .then(d => 
            {
                this.catLoad = false;
                this.libraryitemsRes = d.data.data;
                if (d.data.data.length < 12) {
                    this.getMore_btn = false;
                } else {
                    this.getMore_btn = true;
                }

            });


            // Axios.get("https://alkafeel.net/main_bg/data/Holy_quran_library_test.json")
            // .then(d => 
            // {
            //     this.libraryitemsRes = d.data['items'];
            // });
        },
        library_ini (cat) {
            
            Axios.get("books/getByCat/"+cat+"/6")
            .then(d => 
            {
                this.library_ini_items.push([d.data.data]);
                // console.log(this.library_ini_items);
            });
            
            
        },
        getMore() {
            this.btnLoader = true;
            var p = this.bookPage +1;
            Axios.get("books/getByCat/"+this.catId+"/12?page=" + p, {})
                .then(d => {
                    this.btnLoader = false;
                    this.catLoad = false;
                    this.bookPage++;
                    this.libraryitemsRes = this.libraryitemsRes.concat(d.data.data);
                    // console.log(this.libraryitemsRes.length);
                    if (d.data.data.length < 12) {
                        this.getMore_btn = false;
                    }
                })
        },
    },
    
}
</script>
<style scoped>
    .centered {
        justify-content: center;
    }
    .library {
        width: 100%;
        margin: 0 auto;
        padding: 20px 0 0 0;
    }
    .title p {
        font-size: 38px;
        letter-spacing: normal;
        margin: 0;
    }
    .title {
        padding: 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
    }
    .library_cont {
        max-width: 1260px;
        margin: 0 auto;
        /* border-top: solid thin #C4C4C4; */
        padding-top: 25px;
    }
    .serv2 {
        fill:#098486;
        width: 25px;
        height: 30px;
        margin: 0 10px;
    }
    .greeen svg {
        fill:#FFF;
    }
    .sub {
        color:#000; padding: 10px 0 0 0;
    }
    .main_img {
        height: 89%;
        width: 95%;
    }
    .main_card {
        height: 100%;
        width: 100%;
    }
    .latest_items {
        margin: 5px;
    }
    .latest_items2 {
        margin: 10px;
    }
    .d_align {
        padding-right: inherit;
    }
    /* @media (max-width: 490px) {
        .latest_items, .latest_items2 {
            flex:40%
        } */
    /* } */



    .theme--light.v-btn--active:before, .theme--light.v-btn--active:hover:before {
        opacity: 0 !important;
    }

    .slider .swiper {
        position: relative;
    }

    .slider .swiper .greeen {
        background-color: #424242 !important;
    }

    .tabs .slider .swiper .swiper-wrapper .swiper-slide {
        width: auto;
        display: inline-block;
        height: 40px !important;
    }

    .tabs .slider .swiper .swiper-wrapper .swiper-slide .btn {
        font-size: 22px;
        letter-spacing: normal;
        color: #737373;
    }

    .tabs .slider .swiper .swiper-wrapper .swiper-slide .btn span {
        margin: 0 10px;
    }

    .tabs .slider .sbtn {
        min-width: 16px !important;
        height: 29px;
        align-items: center;
        background-position: center;
        background-repeat: no-repeat;
        margin: 0 10px !important;
        background-size: contain;
    }

    .tabs .slider .sbtn.ltr {
            transform: rotateY(180deg);
    }

    .tabs .slider .sbtn.lbtn2 {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAdBAMAAABRQ/GMAAAAGFBMVEUAAAATbm4Tbm4Sb28TbW0PbW0TbW0Tbm6HmDN0AAAACHRSTlMAul1FryNpUZSxzjkAAAA0SURBVBjTY0hUYIAARyEow1AQKsQsSAuhEJgQK5KNcBG4GlQBE4gAwhhD8gUY4E5JKYDQAKkuCU3unq43AAAAAElFTkSuQmCC");
    }

    .tabs .slider .sbtn.rbtn2 {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAdBAMAAABRQ/GMAAAAG1BMVEUAAAATbm4Tbm4Sb28Tbm4Pb28TbW0Tbm4Rb28ZuIVQAAAACXRSTlMAul1FriJoUS7VW/9mAAAAN0lEQVQY02OAAHY3KENREEIzCQrDBAyoKOABFRAJgDCAKhAiUDWumELODAiDYEZTLCQCZTCJAQBKJwhRmyr4sgAAAABJRU5ErkJggg==");
    }

    .items .bcon {
        margin-top: 25px;
    }

    .items .bcon .item {
        padding: 10px;
    }

    .items .bcon .item a {
        margin: 10px auto;
        text-decoration: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .items .bcon .item a .img {
        max-height: 260px;
        height: 260px;
        width: 100%;
        max-width: 175px;
        object-fit: cover;
        overflow: hidden;
        position: relative;
    }

    .items .bcon .item a p {
        font-weight: 800;
        font-size: 18px;
        font-family: cairo_bold;
        color: #303030;
        max-width: 175px;
        margin: 10px 0 0 0;
    }

    .items .mcon .slider {
        width: 100%;
    }
</style>
